div[class*="c-block"] {
    &[class*="--text"] {
        padding: 39px 10px 37px;
        height: 100%;
        a.moreInfos {
            text-align: center;
            display: block;
            color: $green-2;
            margin-top: 10px;
        }
    }

    i {
        margin: auto;
        text-align: center;
        font-size: 40px;
        display: block;
        margin-bottom: 15px;
    }

    h3 {
        text-align: center;
        font-size: 17px;
        color: $black;
    }
}