@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
.text-danger {
  color: #dc006b !important; }

.text-success {
  color: #5db994 !important; }

.text-success-2 {
  color: #267053; }

.text-green {
  color: #009353 !important; }

.text-dark {
  color: #5c012d !important; }

.text-yellow {
  color: #ffd000; }

a.text-grey {
  color: #888;
  transition: color 1s ease; }

a.text-danger {
  color: #dc006b;
  transition: color 1s ease; }
  a.text-danger:hover {
    color: #625c5c !important;
    text-decoration: none;
    transition: color 1s ease; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #625c5c !important;
  color: #FFF !important; }

.bg-grey {
  background-color: #ebebeb36; }

.navBar {
  width: 100%;
  max-width: 1800px;
  display: flex !important;
  justify-content: center;
  margin: 14px auto 0;
  background-color: #FFF;
  position: relative;
  height: 120px;
  align-items: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  position: sticky;
  top: 0;
  box-shadow: 0 16px 15px -19px #aba3a3ab;
  z-index: 99989; }
  @media (max-width: 1855px) {
    .navBar {
      width: 100%;
      margin: 0 auto; } }
  .navBar .container-fluid {
    max-width: 100% !important; }
  .navBar a {
    font-size: 13px;
    font-weight: 500; }
  .navBar .navbar-logo {
    position: relative; }
    .navBar .navbar-logo::after {
      display: block;
      content: "...Une histoire de famille";
      position: absolute;
      left: 220px;
      top: 45px;
      font-family: 'Dancing Script', cursive;
      font-size: 20px;
      font-weight: normal;
      color: #625c5c; }
      @media (max-width: 1390px) {
        .navBar .navbar-logo::after {
          position: relative;
          left: 6px;
          top: 0; } }
    .navBar .navbar-logo img {
      max-width: 200px; }
  .navBar .nav-item.active .btn {
    background-color: #dc006b;
    color: #FFF;
    font-weight: 600; }
  .navBar .btn {
    background-color: #FFF;
    color: #625c5c;
    padding: 10px 35px;
    box-shadow: 0px 14px 20px -10px #f3f0f0;
    transition: background-color 0.3s ease-in-out;
    margin: auto;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    font-size: 15px; }
    @media (max-width: 1400px) {
      .navBar .btn {
        padding: 10px 20px; } }
    .navBar .btn:hover {
      background-color: #dc006b;
      color: #FFF;
      transition: background-color 0.2s ease-in-out; }
  .navBar .navbar-collapse {
    text-align: right;
    margin-right: 30px; }
  .navBar .dropdown .dropdown-menu {
    background-color: rgba(98, 92, 92, 0.6);
    padding: 10px;
    right: 0;
    left: auto;
    border: 0;
    opacity: 0;
    animation: appearanceFade 0.5s ease-in-out 0.1s forwards; }
    .navBar .dropdown .dropdown-menu a {
      margin: 10px auto 0; }

.navbar-brand {
  margin-right: 0; }

.navbar {
  padding: 0; }
  @media (max-width: 1200px) {
    .navbar .navbar-collapse {
      width: 40%;
      margin: auto;
      /* padding: 0; */
      text-align: center;
      top: 80px;
      position: absolute;
      right: 0;
      background-color: #FFF;
      border-radius: 0 0 20px 20px;
      box-shadow: 1px 31px 51px #f5f5f5; }
      .navbar .navbar-collapse .btn {
        box-shadow: none;
        width: 100%;
        margin-left: 0 !important; } }
  @media (max-width: 425px) {
    .navbar .navbar-collapse {
      width: 100%; } }

.navbar-toggler {
  background-color: #FFF;
  right: 50px;
  color: #625c5c; }

.filter {
  display: block;
  top: 10px;
  right: 0;
  padding: 20px;
  border-radius: 15px;
  z-index: 500;
  animation: fadeAnimation 0.5s; }
  .filter .noUi-connect {
    background-color: #f5f5f5 !important; }
  .filter .noUi-target {
    box-shadow: 7px 0px 10px #f5f5f5 !important;
    border: none; }
  .filter .noUi-handle {
    box-shadow: 7px 0px 10px #f5f5f5 !important;
    border: none; }

.card {
  border: 0 !important; }

.card-text {
  font-weight: 400; }
  @media (max-width: 575px) {
    .card-text {
      padding-bottom: 0 !important; } }

.card-text strong {
  color: #5c012d; }

.cardIndex a {
  color: #625c5c;
  text-decoration: none;
  overflow: hidden;
  transition: 1s color ease-in-out; }
  .cardIndex a:hover {
    transition: 1s color ease-in-out; }

.cardIndex [class*='card'] {
  border: 0 !important;
  transition: 1s box-shadow ease; }
  @media (max-width: 575px) {
    .cardIndex [class*='card'] {
      flex-direction: row; } }
  .cardIndex [class*='card'] .surface-piece--location {
    font-size: 25px;
    bottom: 6px;
    right: 25px; }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .surface-piece--location {
        color: white !important;
        bottom: -15px;
        font-size: 20px; } }
  .cardIndex [class*='card'] .card-title {
    font-size: 15px;
    color: #4c4c4c; }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .card-title {
        font-size: 12px; } }
  .cardIndex [class*='card'] .card-description {
    color: #4c4c4c; }
  .cardIndex [class*='card'][class*='--noshadow'] {
    box-shadow: none;
    border-radius: 0; }
    .cardIndex [class*='card'][class*='--noshadow']:hover {
      box-shadow: none; }
  .cardIndex [class*='card']:hover {
    transition: 1s box-shadow ease-in-out;
    box-shadow: 8px 6px 41px #d5d8dc; }
    .cardIndex [class*='card']:hover .btn--transaction {
      background-color: #dc006b !important;
      transition: 0.5s all ease-in-out !important; }
    .cardIndex [class*='card']:hover .surface-piece--location {
      color: #d5d8dc !important;
      transition: 0.5s color ease; }
    .cardIndex [class*='card']:hover .card-body {
      background-color: #ebebeb36;
      transition: 1s all ease-in-out; }
    .cardIndex [class*='card']:hover .card-text {
      box-shadow: none; }
    .cardIndex [class*='card']:hover .card-title {
      box-shadow: none; }
    .cardIndex [class*='card']:hover .claimedRight {
      transition: 0.5s all ease;
      background-color: #FFF; }
    .cardIndex [class*='card']:hover .card-img {
      margin: auto;
      overflow: hidden;
      transition: 0.5s all ease-in-out; }
      .cardIndex [class*='card']:hover .card-img::before {
        opacity: 0.5;
        transition: 2s all ease-in-out; }
      .cardIndex [class*='card']:hover .card-img::after {
        transition: 1s all ease-in-out;
        opacity: 0;
        animation: appearanceDown 0.5s ease-in-out 0.1s forwards; }
    .cardIndex [class*='card']:hover .my360-logo {
      background-color: rgba(220, 0, 107, 0.8) !important; }
    .cardIndex [class*='card']:hover .surface--image {
      background-color: rgba(92, 1, 45, 0.8);
      transition: 1s ease;
      color: #FFF; }
  .cardIndex [class*='card'] .surface--image {
    opacity: 0;
    animation: appearanceFade 0.6s ease-in 0.8s forwards;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    color: #625c5c;
    font-weight: bold;
    top: 5px;
    right: 3px;
    padding: 3px 5px 3px 9px;
    border-radius: 20px 20px 0 20px; }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .surface--image {
        right: 0;
        top: 25px;
        border-radius: 20px 0 0 20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white; } }
  .cardIndex [class*='card'] .claimedRight {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    color: #625c5c;
    top: 20.7rem;
    left: 0px;
    font-size: 18px;
    padding: 5px 12px;
    border-radius: 0 20px 0 0;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1500px) {
      .cardIndex [class*='card'] .claimedRight {
        top: 17.8rem; } }
    @media (max-width: 1300px) {
      .cardIndex [class*='card'] .claimedRight {
        top: 17.2rem;
        font-size: 14px;
        padding: 4px 10px;
        border-radius: 0 25px 0 0; } }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .claimedRight {
        top: 17rem;
        bottom: auto;
        font-size: 16px;
        padding: 4px 40px; } }
  .cardIndex [class*='card'] .claimedTop {
    position: absolute;
    background-color: rgba(220, 0, 107, 0.8);
    color: #FFF;
    top: 0;
    left: 0px;
    font-size: 12px;
    padding: 5px 10px 5px 5px;
    border-radius: 15px 20px 20px 0;
    transition: all 0.3s ease-in-out; }
  .cardIndex [class*='card'] .card-pub {
    height: 30rem;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0 0;
    box-shadow: none;
    background-position: center; }
    @media (max-width: 1500px) {
      .cardIndex [class*='card'] .card-pub {
        height: 25rem; } }
    @media (max-width: 1300px) {
      .cardIndex [class*='card'] .card-pub {
        height: 26rem; } }
  .cardIndex [class*='card'] .card-img-tp {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 23rem;
    border-radius: 25px 25px 0 0 !important;
    background-color: #f5f5f5; }
    @media (max-width: 1500px) {
      .cardIndex [class*='card'] .card-img-tp {
        height: 20rem; } }
    @media (max-width: 1300px) {
      .cardIndex [class*='card'] .card-img-tp {
        height: 19rem; } }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .card-img-tp {
        border-radius: 10px; } }
  .cardIndex [class*='card'] .card-img {
    background-image: url("https://agence-immobiliere-coulon.fr/images/divers/no-image.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
    margin: auto;
    object-fit: cover;
    display: block;
    box-shadow: -28px 24px 8px -24px #d2d2d2; }
    .cardIndex [class*='card'] .card-img::before {
      background: linear-gradient(32deg, #000000 24%, #4a3838 40%, #ffffff 82%, white 100%);
      height: 100%;
      width: 100%;
      display: block;
      content: '';
      opacity: 0;
      transition: 0.5s ease-out; }
    .cardIndex [class*='card'] .card-img::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: '\f00e';
      color: #FFF;
      position: absolute;
      font-size: 40px;
      top: 45%;
      left: 45%;
      z-index: 999;
      transform: translate(-50%, -50%);
      transition: 2s ease-out;
      opacity: 0; }
  .cardIndex [class*='card'] .my360-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    font-size: 11px;
    padding: 6px 10px 5px 20px;
    letter-spacing: 2px;
    border-radius: 20px 0 0 0;
    background-color: rgba(92, 1, 45, 0.7);
    transition: 1s ease;
    color: #FFF; }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .my360-logo {
        left: 0;
        right: auto;
        bottom: 31px;
        font-size: 9px;
        padding: 5px 10px;
        border-radius: 20px 20px 0 0; } }
  .cardIndex [class*='card'] .card-body {
    text-align: left;
    height: 100px;
    padding: 15px 15px 20px;
    background-color: #f7f7f7;
    border-radius: 0 0 25px 25px;
    transition: 1s background-color ease;
    hyphens: auto; }
    @media (max-width: 1340px) {
      .cardIndex [class*='card'] .card-body {
        height: 100px; } }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .card-body {
        height: 15rem;
        width: 40%;
        border-radius: 0 25px 25px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        word-break: break-word;
        width: 50%; }
        .cardIndex [class*='card'] .card-body .text-sm {
          font-size: 12px !important; }
        .cardIndex [class*='card'] .card-body .card-title {
          position: absolute;
          font-weight: 600 !important;
          top: 53px;
          color: white;
          padding: 5px 6px 5px 10px;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          text-align: right;
          border-radius: 20px 0 0 20px; }
        .cardIndex [class*='card'] .card-body .card-text {
          position: absolute;
          top: 84px;
          padding: 5px 6px 5px 10px !important;
          color: white !important;
          font-weight: 600;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          text-align: right;
          text-align: right;
          line-height: 13px;
          border-radius: 20px 0 0 20px; }
          .cardIndex [class*='card'] .card-body .card-text .text-danger {
            color: #ff9e9e !important; }
        .cardIndex [class*='card'] .card-body .btn {
          display: none; } }
    @media (max-width: 575px) {
      .cardIndex [class*='card'] .card-body {
        height: 18rem;
        width: 0;
        padding: 0; } }
    .cardIndex [class*='card'] .card-body a {
      text-decoration: none;
      color: #625c5c !important; }
      .cardIndex [class*='card'] .card-body a:hover {
        text-decoration: none;
        color: #625c5c; }
    .cardIndex [class*='card'] .card-body .btn {
      position: absolute;
      bottom: 0;
      right: 5px;
      color: #d5d8dc;
      font-size: 15px;
      font-weight: 400;
      font-style: italic;
      transition: 0.5s color ease; }

.cardIndex .card.col-lg-3 {
  flex: 0 0 30% !important;
  max-width: 30% !important; }

.cardIndex .admin .card-body {
  height: 100%; }

.cardPromotion h4 {
  font-size: 17px;
  font-weight: bold; }

.cardPromotion h6 {
  font-size: 15px; }

.cardPromotion .card-text {
  font-size: 15px; }

.card--transactions .card-body {
  height: 85px !important; }

[class*='surface-piece'] {
  position: absolute;
  right: 15px;
  bottom: -10px;
  font-size: 40px;
  color: #dc006b1f;
  font-weight: 400;
  font-style: italic;
  transition: all 0.5s; }
  [class*='surface-piece'][class*='--location'] {
    font-size: 20px;
    bottom: -15px; }
  [class*='surface-piece'][class*='--transactions'] {
    font-size: 30px;
    bottom: -15px; }

.text-xs {
  font-size: 10px; }

.text-sm {
  font-size: 12px; }

.text-md {
  font-size: 14px; }

.text-lg {
  font-size: 20px; }

.text-xl {
  font-size: 50px; }

.resume-infos {
  border-radius: 20px 0 20px 0;
  display: block;
  font-weight: 300; }
  .resume-infos ul {
    list-style-type: none;
    padding-left: 0; }
  .resume-infos h1 {
    font-weight: 400;
    font-size: 20px;
    text-align: center; }
  .resume-infos h2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #dc006b; }
  .resume-infos [class*="--description"] {
    text-align: left; }
    .resume-infos [class*="--description"] .text-lg {
      color: #78c2ad; }

span[class*="test"] {
  background: #ffff00; }

.energie-global {
  margin-top: 0;
  text-align: center; }
  @media (max-width: 763px) {
    .energie-global {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: left; } }

span[class*="energie"] {
  padding: 0 .35rem;
  color: #FFF;
  font-weight: 600;
  font-size: 11px; }
  span[class*="energie"].active {
    font-size: 20px;
    padding: 0 10px;
    border: 2px solid #FFF;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px; }
  span[class*="energie"][class*="--A"] {
    background: #379932; }
  span[class*="energie"][class*="--B"] {
    background: #3acc31; }
  span[class*="energie"][class*="--C"] {
    background: #cdfd33;
    color: #625c5c; }
  span[class*="energie"][class*="--D"] {
    background: #fbea49;
    color: #625c5c; }
  span[class*="energie"][class*="--E"] {
    background: #fccc2f;
    color: #625c5c; }
  span[class*="energie"][class*="--F"] {
    background: #fb9c34; }
  span[class*="energie"][class*="--G"] {
    background: #fa1c1f; }

span[class*="ges"] {
  padding: 0 .35rem;
  color: #625c5c;
  font-weight: 600;
  font-size: 11px; }
  span[class*="ges"].active {
    font-size: 20px;
    padding: 0 10px;
    border: 2px solid #FFF;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px; }
  span[class*="ges"][class*="--A"] {
    background: #f6edfe; }
  span[class*="ges"][class*="--B"] {
    background: #e4c7fb; }
  span[class*="ges"][class*="--C"] {
    background: #d2adf1; }
  span[class*="ges"][class*="--D"] {
    background: #c99aef; }
  span[class*="ges"][class*="--E"] {
    background: #b77ae9;
    color: #FFF; }
  span[class*="ges"][class*="--F"] {
    background: #a659e9;
    color: #FFF; }
  span[class*="ges"][class*="--G"] {
    background: #8835d9;
    color: #FFF; }

.city-picture {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-top: 20px; }

[class*="text-city"] {
  padding: 0 50px;
  text-align: justify; }
  @media (max-width: 991px) {
    [class*="text-city"] {
      padding: 0; } }
  [class*="text-city"][class*="--transaction"] {
    padding: 30px 30px 30px 0; }
    @media (max-width: 991px) {
      [class*="text-city"][class*="--transaction"] {
        padding: 30px 30px 0 30px; } }

.filter .form-control {
  border: none;
  border-bottom: 1px solid #f5f5f5;
  padding: 5px 10px;
  outline: none;
  border-radius: 0; }
  @media (max-width: 500px) {
    .filter .form-control {
      text-align: center; } }

.filter [placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1; }

.filter input:in-range {
  box-shadow: none !important; }

.filter .range {
  -webkit-appearance: none;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  width: 100%;
  height: 25px;
  background: #f5f5f5;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s; }
  .filter .range:hover {
    opacity: 1; }
  .filter .range:focus {
    background: #f5f5f5; }
  .filter .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #625c5c;
    border-radius: 50%;
    cursor: pointer; }
  .filter .range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #625c5c;
    cursor: pointer; }

.filter output {
  margin-bottom: 20px;
  color: #625c5c;
  font-weight: bold; }

.contact-us {
  position: relative !important; }
  .contact-us a {
    color: #888;
    transition: color 0.3s ease; }
    .contact-us a:hover {
      color: #dc006b;
      text-decoration: none;
      transition: color 1s ease; }
  .contact-us .form-control {
    border: 0;
    background-color: #f5f5f5; }
  .contact-us .mail {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f1fa';
    font-size: 100px;
    color: rgba(220, 0, 107, 0.1);
    transform: rotate(10deg);
    position: absolute;
    right: 25px;
    bottom: 0;
    z-index: 999; }
    @media (max-width: 1769px) {
      .contact-us .mail {
        right: 0; } }
    @media (max-width: 770px) {
      .contact-us .mail {
        display: none; } }

.form-filtrage {
  padding: 30px 30px 0;
  color: #625c5c; }
  .form-filtrage #submitSearch {
    margin-top: 20px;
    margin-bottom: 10px; }
  @media (max-width: 575px) {
    .form-filtrage {
      padding: 0; }
      .form-filtrage div {
        padding: 0; }
      .form-filtrage #submitSearch {
        margin-top: 10px; } }

.sortBy {
  position: absolute;
  right: 20px; }
  .sortBy a {
    transition: 0.8s color ease;
    color: #625c5c;
    text-decoration: none; }
    .sortBy a .fas {
      transition: 0.5s all ease;
      transform: rotate(0); }
    .sortBy a span {
      font-weight: 600; }
    .sortBy a:hover {
      transition: 0.8s color ease;
      color: #625c5c; }
      .sortBy a:hover span {
        transition: 1s all ease; }
      .sortBy a:hover .fas {
        transition: 1.5s all ease;
        color: #625c5c !important;
        transform: rotate(-360deg); }
  @media (max-width: 1205px) {
    .sortBy {
      position: static;
      width: 100%;
      padding-top: 5px; } }

@media (max-width: 575px) {
  .hidden {
    display: none; } }

.form-control {
  color: #625c5c;
  font-weight: bold;
  vertical-align: baseline !important;
  border: 0;
  border-bottom: 1px solid #f5f5f5;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5); }
  .form-control::placeholder {
    font-weight: normal;
    color: #d5d8dc; }
  .form-control:focus {
    box-shadow: none !important;
    border-bottom: 1px solid #dc006b; }

textarea {
  border: 1px solid #f5f5f5 !important; }

.pagination {
  padding: 20px; }
  .pagination .page-item.active .page-link {
    background-color: #dc006b !important; }
  .pagination .page-link {
    background-color: #5c012d !important;
    border: 0px !important;
    transition: background-color 1s ease;
    padding: 5px 10px; }
    .pagination .page-link:hover {
      background-color: #5c012d !important;
      transition: background-color 1s ease; }
  .pagination,
  .pagination * {
    user-select: none; }
  .pagination a {
    display: inline-block;
    padding: 0 10px;
    cursor: pointer; }
    .pagination a.disabled {
      opacity: .3;
      pointer-events: none;
      cursor: not-allowed; }
    .pagination a.current {
      background: #f3f3f3; }

.paginate-center {
  margin-left: 20px !important;
  max-width: 80%; }
  @media (max-width: 1205px) {
    .paginate-center {
      max-width: 100%;
      justify-content: center;
      margin: auto;
      text-align: center;
      margin-left: 0 !important; } }

[class*="card-img-slider"] {
  width: 100%;
  max-height: 600px;
  height: 65vh;
  object-fit: cover;
  border-radius: 20px;
  transition: all 1s; }
  @media (max-width: 790px) {
    [class*="card-img-slider"] {
      height: 25vh; } }
  [class*="card-img-slider"]::before {
    background: linear-gradient(32deg, #000000 24%, #4a3838 40%, #ffffff 82%, white 100%);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    display: block;
    content: '';
    opacity: 0;
    transition: 0.5s ease-out; }
  [class*="card-img-slider"]::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\f00e';
    color: #FFF;
    position: absolute;
    font-size: 40px;
    top: 45%;
    left: 48%;
    z-index: 999;
    transform: translate(-50%, -50%);
    transition: 2s ease-out;
    opacity: 0; }
  [class*="card-img-slider"][class*="--nopic"] {
    background-size: 50%; }
  [class*="card-img-slider"][class*="--transaction"] {
    height: 600px;
    border-radius: 20px; }

.count-pictures {
  position: absolute;
  bottom: 0;
  left: -1px;
  border-radius: 0 20px 0 0;
  background: white;
  padding: 8px 15px;
  color: #625c5c;
  font-size: 11px; }

.see-more {
  position: absolute;
  bottom: 0;
  right: -1px;
  border-radius: 20px 0 0 0;
  background: white;
  padding: 10px 20px;
  color: #dc006b;
  font-size: 20px; }
  .see-more i {
    animation: zooming infinite 1s; }

[class*="img-top"] {
  display: block;
  width: 100%;
  background-position-x: center;
  background-image: url("https://agence-immobiliere-coulon.fr/images/divers/no-image.png");
  height: 420px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; }
  [class*="img-top"][class*="--small"] {
    background-position-x: 100px;
    background-size: contain; }
  [class*="img-top"][class*="--blog"] {
    width: 80%;
    margin: auto;
    background-size: cover; }
  [class*="img-top"] .animate-text--img h1 {
    color: #FFF;
    background-color: rgba(98, 92, 92, 0.2);
    padding: 10px 20px;
    font-weight: 600;
    font-size: 30px;
    position: absolute;
    top: 60px;
    opacity: 0;
    animation: appearanceLeft 1s ease-in-out 0.5s forwards;
    border-radius: 0 10px 10px 0;
    letter-spacing: 5px; }
    [class*="img-top"] .animate-text--img h1.h1SliderHome {
      top: 75px; }
    @media (max-width: 790px) {
      [class*="img-top"] .animate-text--img h1 {
        font-size: 21px;
        top: 40px; }
        [class*="img-top"] .animate-text--img h1.h1SliderHome {
          top: 20px;
          font-size: 15px; } }
  [class*="img-top"] .animate-text--img h2, [class*="img-top"] span {
    color: #FFF;
    background-color: rgba(98, 92, 92, 0.2);
    padding: 10px 20px;
    position: absolute;
    top: 120px;
    opacity: 0;
    animation: appearanceRight 1.5s ease-in-out 0.5s forwards;
    font-size: 25px;
    border-radius: 0 10px 10px 0;
    letter-spacing: 3px; }
    [class*="img-top"] .animate-text--img h2.h2SliderHome, [class*="img-top"] span.h2SliderHome {
      border-radius: 10px 0 0 10px;
      bottom: 41px;
      right: 0;
      top: auto;
      font-size: 16px; }
    @media (max-width: 790px) {
      [class*="img-top"] .animate-text--img h2, [class*="img-top"] span {
        top: 100px;
        padding: 5px 20px;
        font-size: 20px;
        background-color: rgba(98, 92, 92, 0.5); }
        [class*="img-top"] .animate-text--img h2.h2SliderHome, [class*="img-top"] span.h2SliderHome {
          bottom: 8px;
          right: 0;
          top: auto;
          padding: 5px 10px 5px 15px;
          font-size: 11px; } }
  @media (max-width: 790px) {
    [class*="img-top"] .animate-text--img span {
      top: 80px;
      padding: 5px 20px;
      font-size: 12px; } }

.opinion-system {
  text-align: center;
  color: #888;
  position: relative; }
  .opinion-system .carousel-item {
    background-color: white; }
  .opinion-system .text {
    display: block;
    border-radius: 10px 0 10px 0;
    z-index: 0; }
    .opinion-system .text .o-sys-logo {
      position: absolute;
      width: 60px;
      left: 50%;
      top: -8px;
      transform: translate(-50%, -50%); }
      @media (max-width: 770px) {
        .opinion-system .text .o-sys-logo {
          width: 40px;
          left: 50%;
          top: 0; } }

.carousel-inner {
  width: 95%;
  margin: auto; }

.carousel-item {
  transition: all 1s;
  background-color: #f5f5f5;
  border-radius: 20px; }
  @media (max-width: 770px) {
    .carousel-item {
      font-size: 11px; } }
  .carousel-item::after {
    content: 'zoom';
    font-size: 38px;
    right: 0;
    bottom: 0;
    display: block;
    content: '';
    opacity: 1;
    transition: 0.5s ease-out; }
  .carousel-item:hover .card-img-slider {
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;
    transition: 1s ease;
    box-shadow: 0 0 10px 2px #f5f5f5; }
    .carousel-item:hover .card-img-slider::before {
      opacity: 0.5;
      transition: 2s ease; }
    .carousel-item:hover .card-img-slider::after {
      transition: 1s ease;
      opacity: 0;
      animation: appearanceDown 0.5s ease-in-out 0.1s forwards; }

div[class*="c-block"][class*="--text"] {
  padding: 39px 10px 37px;
  height: 100%; }
  div[class*="c-block"][class*="--text"] a.moreInfos {
    text-align: center;
    display: block;
    color: #78c2ad;
    margin-top: 10px; }

div[class*="c-block"] i {
  margin: auto;
  text-align: center;
  font-size: 40px;
  display: block;
  margin-bottom: 15px; }

div[class*="c-block"] h3 {
  text-align: center;
  font-size: 17px;
  color: #625c5c; }

@keyframes appearanceLeft {
  0% {
    transform: translateX(-50px) translateZ(0);
    opacity: 0; }
  100% {
    transform: translateX(0) translateZ(0);
    opacity: 1; } }

@keyframes appearanceRight {
  0% {
    transform: translateX(50px) translateZ(0);
    opacity: 0; }
  100% {
    transform: translateX(0) translateZ(0);
    opacity: 1; } }

@keyframes appearanceDown {
  0% {
    transform: translateY(100px) translateZ(0);
    opacity: 0; }
  100% {
    transform: translateY(0) translateZ(0);
    opacity: 1; } }

@keyframes appearanceFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeAnimation {
  0% {
    opacity: 0; }
  25% {
    opacity: 0.25; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes leafDown {
  0% {
    transform: translateY(-1000px) translateZ(0) rotate(-360deg);
    opacity: 1; }
  100% {
    transform: translateY(0) translateZ(0);
    opacity: 1; } }

@-webkit-keyframes animate-logo {
  from {
    transform: rotate(20deg); }
  50% {
    transform: rotate(-30deg); }
  to {
    transform: rotate(20deg); } }

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes zooming {
  50% {
    transform: scale(0.7); }
  100% {
    transform: scale(1); } }

@keyframes blind {
  0% {
    opacity: 1; }
  40% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

#top,
#bottom,
#left,
#right {
  background: #FFF;
  position: fixed;
  z-index: 9999999999; }

#left,
#right {
  top: 0;
  bottom: 0;
  width: 20px; }

#left {
  left: 0;
  background: linear-gradient(32deg, #fafafa 0%, #ffffff 53%, #eeeff3 100%, #d7dadf 100%); }

#right {
  right: 0;
  background: linear-gradient(32deg, #fafafa 0%, #fafafa 53%, #fafafa 100%, #d1d6dc 100%); }

#top,
#bottom {
  left: 0;
  right: 0;
  height: 10px; }

#top {
  top: 0;
  background: linear-gradient(32deg, #eeeff3 0%, #fafafa 53%, #fafafa 100%, #fafafa 100%);
  box-shadow: none; }

#bottom {
  bottom: 0;
  background: linear-gradient(32deg, #fafafa 0%, #dde3ec 53%, #fafafa 100%, #fafafa 100%); }

.blog {
  background-color: #f5f5f5;
  border-radius: 20px; }
  @media (max-width: 790px) {
    .blog {
      padding: 20px 10px !important; } }
  .blog p a {
    color: #6c92b0;
    transition: all 0.51s ease;
    font-weight: bold; }
    .blog p a:hover {
      color: #dc006b;
      transition: all 0.51s ease;
      text-decoration: none; }

.blog .jumbotron {
  display: flex;
  position: relative;
  background: transparent;
  width: 100%;
  padding: 2rem 20px 2rem 0; }
  .blog .jumbotron h3 {
    font-size: 20px;
    color: #625c5c;
    font-weight: 500; }
  .blog .jumbotron p {
    font-size: 1rem; }
  .blog .jumbotron:nth-child(even) {
    flex-flow: row-reverse nowrap; }
    .blog .jumbotron:nth-child(even) h3 {
      color: #625c5c;
      text-align: left; }
    .blog .jumbotron:nth-child(even) .side-text {
      left: 30px;
      text-align: left;
      background-color: #FFF;
      color: #625c5c;
      box-shadow: 2px 1px 5px #dbe0cdab; }
      @media (max-width: 790px) {
        .blog .jumbotron:nth-child(even) .side-text {
          left: 0; } }
      .blog .jumbotron:nth-child(even) .side-text::after {
        left: -20px;
        bottom: 0;
        background-image: url("https://agence-immobiliere-coulon.fr/images/divers/fly.png");
        height: 50px;
        width: 50px;
        transform: rotate(-16deg); }
        @media (max-width: 790px) {
          .blog .jumbotron:nth-child(even) .side-text::after {
            display: none; } }
    .blog .jumbotron:nth-child(even) .side-pic {
      left: -40px;
      transform: rotate(4deg); }
      @media (max-width: 790px) {
        .blog .jumbotron:nth-child(even) .side-pic {
          left: 0;
          transform: rotate(0); } }
  .blog .jumbotron:nth-child(odd) h3 {
    text-align: right; }
  @media (max-width: 790px) {
    .blog .jumbotron:nth-child(odd) .side-pic {
      transform: rotate(0deg); } }
  @media (max-width: 790px) {
    .blog .jumbotron:nth-child(odd) .side-text {
      right: 0;
      transform: rotate(0deg); } }
  .blog .jumbotron:nth-child(odd) .side-text::after {
    right: -90px;
    top: 0;
    background-image: url("https://agence-immobiliere-coulon.fr/images/divers/fly2.png");
    height: 150px;
    width: 150px; }
    @media (max-width: 990px) {
      .blog .jumbotron:nth-child(odd) .side-text::after {
        display: none; } }
  .blog .jumbotron:hover {
    color: #625c5c;
    text-decoration: none; }
    .blog .jumbotron:hover .btn {
      background-color: #5c012d !important; }
    .blog .jumbotron:hover .side-pic {
      transform: rotate(0);
      transition: 1s ease; }
  .blog .jumbotron .side-text {
    color: #625c5c;
    width: 80%;
    background-color: #FFF;
    top: 30px;
    position: relative;
    padding: 20px;
    border: 2px solid #FFF;
    right: 50px;
    box-shadow: 2px 1px 5px #dbe0cdab;
    z-index: 1;
    text-align: right;
    padding: 25px 50px; }
    @media (max-width: 790px) {
      .blog .jumbotron .side-text {
        width: 100%; } }
    .blog .jumbotron .side-text::after {
      display: block;
      content: "";
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat; }
  .blog .jumbotron .side-pic {
    position: relative;
    display: block;
    width: 60%;
    max-height: 250px;
    background-color: #FFF;
    border-radius: 20px;
    left: 30px;
    transform: rotate(-4deg);
    transition: all 1s ease;
    background-size: cover;
    background-position: center;
    box-shadow: 15px 11px 20px -19px #625c5c;
    z-index: 0; }
    @media (max-width: 790px) {
      .blog .jumbotron .side-pic {
        position: absolute;
        top: 1px;
        height: 200px;
        max-height: 250px;
        width: 100%;
        left: 0; } }
  .blog .jumbotron .btn {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 50%); }

.blog-article {
  padding: 50px 70px; }
  .blog-article h1 {
    font-size: 30px;
    text-align: left;
    color: #dc006b; }
  .blog-article h2 {
    font-size: 25px;
    text-align: left !important;
    color: #000 !important; }
  .blog-article h3 {
    font-size: 20px;
    text-align: left !important;
    color: #dc006b !important; }
  .blog-article h4 {
    font-size: 20px;
    text-align: left !important;
    color: #dc006b !important; }
  @media (max-width: 1000px) {
    .blog-article {
      padding: 30px 10px; } }
  .blog-article p, .blog-article li {
    font-weight: 400 !important;
    color: #000;
    font-size: 16px; }
  .blog-article p strong {
    color: #267053 !important; }
  .blog-article span {
    font-weight: 400; }

#map {
  z-index: 0;
  display: block;
  border-radius: 20px;
  position: relative; }

.btn-map {
  position: absolute;
  bottom: 5px;
  left: 16px; }

.btn-bigmap {
  position: absolute;
  bottom: 20px;
  left: 30px; }

.template-announce #map {
  height: 350px; }

.map-global {
  height: 80vh !important; }

@media (max-width: 991px) {
  .map-info {
    padding-bottom: 10px; } }

@media (max-width: 463px) {
  .map-info {
    font-size: 12px; } }

.map--location {
  height: 29.5rem !important; }

.block-pointer #map {
  display: block;
  width: 100%;
  position: relative;
  border-radius: 15px; }
  @media (max-width: 991px) {
    .block-pointer #map {
      pointer-events: none; } }

.block-pointer #map.clicked {
  pointer-events: auto; }

.img-map {
  transform: rotate(-7deg); }

.marker.is-active {
  z-index: 300;
  background-color: #625c5c !important;
  color: #FFF; }
  .marker.is-active::after {
    border-top-color: #625c5c !important; }

.marker.is-expanded {
  z-index: 301;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  height: 260px !important;
  width: 200px !important; }
  .marker.is-expanded img {
    display: block !important; }
  .marker.is-expanded .btn-danger {
    display: block !important; }
  .marker.is-expanded #close-button {
    display: block !important;
    color: #FFF !important;
    background-color: #dc006b;
    border: 0;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer !important; }
  .marker.is-expanded .ref {
    display: block !important; }
  .marker.is-expanded .date {
    display: block !important; }

.marker.is-opened {
  background-color: #dc006b !important;
  color: #FFF !important;
  font-weight: 600 !important; }
  .marker.is-opened h2 {
    color: #FFF !important; }

.marker.leaflet-popup {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  text-align: center;
  width: 100px;
  box-shadow: 0px 0px 3px #f5f5f5;
  border: 1px solid #f5f5f5;
  background-color: #FFF;
  opacity: 0.9 !important; }
  .marker.leaflet-popup img {
    display: none; }
  .marker.leaflet-popup .btn-danger {
    display: none; }
  .marker.leaflet-popup #close-button {
    display: none; }
  .marker.leaflet-popup .ref {
    display: none; }
  .marker.leaflet-popup .date {
    display: none; }
  .marker.leaflet-popup a {
    cursor: pointer; }
  .marker.leaflet-popup::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #625c5c; }

.marker .leaflet-popup-content-wrapper {
  background: transparent;
  color: inherit;
  box-shadow: none;
  text-align: inherit;
  cursor: pointer; }

.marker .leaflet-popup-tip-container {
  display: none; }

.marker .leaflet-popup-content {
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0; }

.leaflet-container a {
  -webkit-tap-highlight-color: #abcb59;
  text-decoration: none; }

.footer {
  background-color: #FFF;
  position: relative;
  height: 100%;
  align-items: center;
  background-image: url("https://i.ibb.co/2kS2Hhd/fondheader.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  animation: appearanceFade 0.1s ease-in 0s forwards;
  font-family: 'Outfit', sans-serif !important;
  border-radius: 10px 10px 0 0;
  padding: 30px 120px !important; }
  @media (max-width: 991px) {
    .footer {
      padding: 30px !important; } }
  .footer a {
    color: #625c5c;
    transition: all 1s ease;
    font-weight: 400; }
    .footer a:hover {
      color: #dc006b;
      text-decoration: none;
      transition: all 1s ease; }
  .footer .container {
    padding: 50px 0 !important; }
  .footer .c-logo--footer {
    max-width: 70px; }
  .footer .load {
    max-width: 200px;
    margin: auto;
    text-align: center;
    display: block;
    animation: appearanceFade 1s ease-in 0s forwards; }
  .footer ul {
    list-style: none;
    padding: 0; }
    .footer ul li {
      padding: 4px; }

.fancybox__container {
  z-index: 9999999999999999 !important;
  --fancybox-bg: #ffffffeb;
  margin: auto; }

.fancybox__nav .f-button {
  color: #dc006b;
  background: #FFF;
  text-shadow: 0 0 #FFF;
  border: 2px solid #dc006b; }
  .fancybox__nav .f-button:hover {
    color: #FFF !important;
    background: #dc006b !important; }
  @media (max-width: 1100px) {
    .fancybox__nav .f-button {
      color: #FFF;
      background: #dc006b;
      width: 35px;
      height: 35px; } }

.fancybox__toolbar .f-button {
  color: #dc006b;
  background: #FFF;
  text-shadow: 0 0 #FFF; }
  .fancybox__toolbar .f-button:hover {
    color: #FFF !important;
    background: #dc006b !important; }

.is-idle .fancybox__nav {
  animation: none; }

.fancybox__infobar {
  color: #dc006b;
  font-weight: 600;
  text-shadow: 0 0 #FFF;
  background: #FFF; }

.fancybox-image {
  border-radius: 15px; }

.fancybox__footer {
  background: #ffffffd9 !important;
  border-radius: 20px 20px 0 0; }

.faq {
  padding: 20px 0 20px;
  background: #f7f7f7;
  min-height: 100vh;
  border-radius: 20px; }

.faq .section-title {
  margin-bottom: 54px; }

.faq .section-title h2 {
  margin-bottom: 22px; }

.faq .accordion .card {
  border: none;
  margin-bottom: 5px; }

.faq .accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 10px; }

.faq .accordion .card .card-header {
  border: none;
  border-radius: 10px;
  padding: 0; }

.faq .accordion .card .card-header h5 {
  padding: 0; }

.faq .accordion .card .card-header h5 button {
  color: #625c5c;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 30px 0 70px;
  height: 80px;
  display: block;
  width: 100%;
  color: rgba(30, 48, 86, 0.8);
  text-align: left;
  background-color: #FFF;
  -webkit-box-shadow: 0px -50px 140px 0px rgba(69, 81, 100, 0.1);
  box-shadow: 0px -50px 140px 0px rgba(69, 81, 100, 0.1);
  border-radius: 10px 10px 0 0;
  position: relative; }

.faq .accordion .card .card-header h5 button:after {
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: #dc006b;
  text-align: center;
  border: 1px solid #dc006b;
  border-radius: 50%;
  line-height: 100%;
  content: '\f067';
  font-size: 10px;
  line-height: 18px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900; }

.faq .accordion .card .card-header h5 button.collapsed {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(97, 125, 255, 0.2);
  transition: background-color 1000ms linear; }
  .faq .accordion .card .card-header h5 button.collapsed:hover {
    background-color: #f7f7f7;
    transition: background-color 1000ms linear; }

.faq .accordion .card .card-header h5 button[aria-expanded="true"]:after {
  content: '\f068';
  color: #fff;
  background-color: #dc006b; }

.faq .accordion .card .card-body {
  -webkit-box-shadow: 0px 15px 140px 0px rgba(69, 81, 100, 0.1);
  box-shadow: 0px 15px 140px 0px rgba(69, 81, 100, 0.1);
  border-radius: 0 0 10px 10px;
  padding-top: 0;
  margin-top: -6px;
  padding-left: 72px;
  padding-right: 70px;
  padding-bottom: 23px;
  color: rgba(30, 48, 86, 0.8);
  line-height: 30px; }
  @media (max-width: 991px) {
    .faq .accordion .card .card-body {
      padding-left: 10px;
      padding-right: 10px; } }

.text-center--pictures {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 101%;
  padding: 50px 20px;
  margin: auto; }
  .text-center--pictures h3 {
    font-size: 15px;
    padding: 5px;
    font-weight: bold; }
  .text-center--pictures .block-information p {
    color: #625c5c;
    font-weight: 400;
    line-height: 24px;
    font-size: 17px;
    text-align: justify; }

.promotion--block {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 20px 20px 20px #aba3a3ab;
  width: 461px;
  height: 244px;
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #FFF 50%, #FFF 100%); }
  @media (max-width: 790px) {
    .promotion--block {
      width: 205px;
      height: 218px;
      position: fixed;
      box-shadow: 1px 31px 51px #aba3a3ab;
      z-index: 9999999;
      font-size: 12px;
      background: white; } }
  .promotion--block .promotion--block-text {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #5c012d;
    font-size: 16px; }
  .promotion--block .logo-montfleury {
    position: absolute;
    bottom: 95px;
    right: 0; }
    @media (max-width: 790px) {
      .promotion--block .logo-montfleury {
        bottom: 100px; } }

[class*='btn'] {
  border: 0;
  transition: all 0.51s ease;
  font-family: 'Outfit', sans-serif !important; }
  [class*='btn'][class*='--homepage'] {
    color: white !important;
    font-weight: bold;
    font-size: calc(20px + 1 * (85vw - 400px) / 1200);
    padding: 2rem 8rem;
    transition: 0.5s;
    border-radius: 75px; }
    @media (max-width: 1335px) {
      [class*='btn'][class*='--homepage'] {
        padding: 2rem 3rem; } }
    @media (max-width: 770px) {
      [class*='btn'][class*='--homepage'] {
        font-size: calc(15px + 1 * (85vw - 400px) / 1200);
        padding: 1rem 1rem;
        width: 100%;
        border-radius: 15px; } }
    [class*='btn'][class*='--homepage']:hover {
      border-radius: 0;
      transition: 0.7s all ease-in-out; }

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 95%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: #dc006b;
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 95%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #dc006b;
  border-top: 5px solid #dc006b;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

.btn-success {
  background-color: #abcb59 !important; }
  .btn-success:hover {
    background-color: #dc006b !important; }

.btn-light:hover {
  background-color: #dc006b !important;
  color: #FFF !important; }
  .btn-light:hover i {
    color: #FFF !important; }

.btn-warning {
  background-color: #ffd000 !important; }
  .btn-warning:hover {
    background-color: #dc006b !important;
    color: #FFF !important; }

.btn-danger {
  color: #FFF !important;
  background-color: #dc006b !important; }
  .btn-danger:hover {
    background-color: #5c012d !important; }
    .btn-danger:hover a {
      text-decoration: none !important; }
  .btn-danger a {
    color: white !important; }

.btn-green-2 {
  background-color: #78c2ad !important; }
  .btn-green-2:hover {
    background-color: #dc006b !important; }

.btn-dark {
  background-color: #5c012d !important; }
  .btn-dark:hover {
    background-color: #dc006b !important; }

.btn-green {
  background-color: #93af43 !important; }
  .btn-green:hover {
    background-color: #dc006b !important; }

.bg-danger, .btn-danger {
  background-color: #dc006b !important; }

.btn-grey {
  background-color: #6c92b0 !important; }
  .btn-grey:hover {
    background-color: #dc006b !important; }

.btn .fa-paper-plane, .btn .fa-chevron-right, .btn .fa-search {
  transform: rotate(0deg);
  transition: 1s all ease-in-out; }

.btn:hover .fa-paper-plane, .btn:hover .fa-chevron-right, .btn:hover .fa-search {
  transform: rotate(360deg);
  transition: 1s all ease-in-out; }

.sticky-btn {
  position: fixed;
  bottom: 50px;
  left: 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  z-index: 9999999;
  display: none;
  transition: 1s all ease; }
  .sticky-btn.show {
    display: block;
    text-align: center;
    transition: 1s all ease; }
  .sticky-btn img {
    width: 40px;
    position: absolute;
    left: 50%;
    top: -65%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: top 0.5s ease-in-out;
    z-index: -1; }
    @media (max-width: 991px) {
      .sticky-btn img {
        top: -80%; } }
  .sticky-btn .btn {
    font-size: 13px;
    background-color: rgba(92, 1, 45, 0.8);
    color: #FFF;
    border-radius: 15px 15px 0 0;
    transition: background-color 0.3s;
    box-shadow: 3px 4px 18px 0px #625c5c;
    padding: 10px 30px; }
    @media (max-width: 991px) {
      .sticky-btn .btn {
        padding: 10px 20px;
        font-size: 12px; } }
    .sticky-btn .btn:hover {
      background-color: #dc006b;
      transition: background-color 0.3s; }
      .sticky-btn .btn:hover img {
        transition: top 0.5s ease-in-out;
        top: -80%; }

.loader {
  background-color: rgba(255, 255, 255, 0.95);
  cursor: wait;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 9999; }
  .loader img {
    -webkit-animation: zooming 4.2s infinite ease-in-out;
    animation: zooming 4.2s infinite ease-in-out;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 40%; }

.is-loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  opacity: 0.5;
  z-index: 4; }

.is-loading .spinner-border {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  z-index: 5; }

.team {
  font-weight: 300;
  max-width: 1300px;
  margin: auto;
  word-break: break-word; }
  @media (max-width: 992px) {
    .team {
      padding: 35px 10px 0; } }
  @media (max-width: 992px) {
    .team .g-map {
      height: 300px; } }
  .team .all-team {
    padding: 30px 0 50px; }
    @media (max-width: 992px) {
      .team .all-team {
        padding: 30px 0 0; } }
    @media (max-width: 575px) {
      .team .all-team {
        text-align: center; } }
  .team .link-team {
    color: #625c5c !important;
    -webkit-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    font-weight: 400;
    font-size: 14px; }
  .team .cardTeam {
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    cursor: pointer; }
    @media (max-width: 575px) {
      .team .cardTeam {
        padding: 5px 0; } }
    .team .cardTeam:hover .img-team {
      transform: rotate(-2deg);
      transition: all 0.5s ease-in-out;
      filter: drop-shadow(0 0 20px #aba3a3ab) hue-rotate(0.1rad); }
      @media (max-width: 992px) {
        .team .cardTeam:hover .img-team {
          transform: none; } }
  .team .img-team {
    background-image: url("https://agence-immobiliere-coulon.fr/images/divers/no-image.png");
    background-position: center;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(0deg);
    transition: all 1s ease-in-out;
    object-fit: cover;
    box-shadow: inset 0px -14px 40px #dc006b1f;
    border-radius: 50%;
    padding: 0; }
    @media (max-width: 1024px) {
      .team .img-team {
        margin: auto;
        background-position: center;
        border-radius: 0;
        background-size: contain;
        box-shadow: none;
        height: 270px; } }
  .team .link-team:hover {
    -webkit-transform: translate3d(0px, -5px, 0px);
    transform: translate3d(0px, -5px, 0px);
    color: #dc006b !important;
    text-decoration: none; }
  .team .fa-mobile-alt {
    margin-left: 2px;
    margin-right: 10px; }
  .team hr {
    border-top: 2px dashed #dc006b1f;
    width: 100%; }
  .team .subh2 {
    color: #625c5c;
    font-weight: 400;
    line-height: 24px;
    font-size: 17px;
    text-align: justify; }

.snow {
  width: 100%;
  height: 50%;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.3%3Bfill:%23ffffff%3B%7D.st3%7Bopacity:0.1%3Bfill:%23ffffff%3B%7D%3C/style%3E%3Ccircle class='st1' cx='5' cy='8' r='1'/%3E%3Ccircle class='st1' cx='38' cy='3' r='1'/%3E%3Ccircle class='st1' cx='12' cy='4' r='1'/%3E%3Ccircle class='st1' cx='16' cy='16' r='1'/%3E%3Ccircle class='st1' cx='47' cy='46' r='1'/%3E%3Ccircle class='st1' cx='32' cy='10' r='1'/%3E%3Ccircle class='st1' cx='3' cy='46' r='1'/%3E%3Ccircle class='st1' cx='45' cy='13' r='1'/%3E%3Ccircle class='st1' cx='10' cy='28' r='1'/%3E%3Ccircle class='st1' cx='22' cy='35' r='1'/%3E%3Ccircle class='st1' cx='3' cy='21' r='1'/%3E%3Ccircle class='st1' cx='26' cy='20' r='1'/%3E%3Ccircle class='st1' cx='30' cy='45' r='1'/%3E%3Ccircle class='st1' cx='15' cy='45' r='1'/%3E%3Ccircle class='st1' cx='34' cy='36' r='1'/%3E%3Ccircle class='st1' cx='41' cy='32' r='1'/%3E%3C/svg%3E");
  opacity: 0.5;
  background-position: 0px 0px;
  animation: animatedBackground 20s linear infinite; }

.snow div {
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.7%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='1'/%3E%3Ccircle class='st3' cx='43' cy='3' r='1'/%3E%3Ccircle class='st3' cx='31' cy='30' r='2'/%3E%3Ccircle class='st3' cx='19' cy='23' r='1'/%3E%3Ccircle class='st3' cx='37' cy='22' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='1'/%3E%3Ccircle class='st3' cx='8' cy='45' r='1'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='1'/%3E%3Ccircle class='st3' cx='47' cy='32' r='1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='2'/%3E%3Ccircle class='st3' cx='9' cy='27' r='1'/%3E%3Ccircle class='st3' cx='30' cy='9' r='1'/%3E%3Ccircle class='st3' cx='25' cy='15' r='1'/%3E%3Ccircle class='st3' cx='21' cy='45' r='2'/%3E%3Ccircle class='st3' cx='42' cy='45' r='1'/%3E%3C/svg%3E");
  background-position: 0px 0px;
  animation: animatedBackground 15s linear infinite; }

@keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 0px 600px; } }

@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;
    /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */ }
  html, body {
    background-color: white !important; }
  .contact-us {
    display: none; }
  .footer {
    display: none; }
  #photos-announce {
    display: none; }
  .city-picture {
    display: none; }
  #print-pictures {
    display: block !important; }
  #klapty {
    display: none; }
  #select-for-us {
    display: none; }
  #tarteaucitronRoot {
    display: none; }
  .text-city {
    display: none; }
  .btn-danger {
    display: none; }
  #navbar {
    display: none !important; }
  #print-filter {
    display: none !important; }
  #print-map {
    display: none !important; }
  #print-logo {
    display: block !important; }
  .map-info {
    display: none !important; }
  nav {
    display: none !important; }
  .sortBy {
    display: none !important; }
  .cardIndex {
    margin: 0 0 0 15mm; }
  #newsletter {
    display: none !important; }
  .block-pointer #map {
    display: none !important; }
  #contactId {
    display: none !important; }
  #map-section {
    padding-top: 0 !important; }
  .text-city--transaction {
    display: none !important; }
  .adress {
    border-bottom: 1px solid #dc006b;
    padding: 50px 10px 10px; } }

html {
  scroll-behavior: smooth; }

body {
  background-color: #f5f5f5;
  position: relative;
  z-index: 10;
  padding: 0 20px 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Outfit', sans-serif !important;
  overflow-x: hidden;
  color: none !important; }
  @media (max-width: 1835px) {
    body {
      margin: auto;
      padding: 0; } }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .btn, a {
  font-family: 'Outfit', sans-serif !important; }

a {
  color: #dc006b; }
  a:hover {
    text-decoration: none;
    color: #625c5c; }

::selection {
  color: #FFF;
  background: #625c5c; }

::-moz-selection {
  color: #FFF;
  background: #625c5c; }

.container {
  padding: 0 !important;
  max-width: 1550px; }

a.text-dark:hover, a.text-dark:focus {
  color: #dc006b !important;
  text-decoration: none; }

.position-sticky--transaction {
  position: sticky !important;
  top: 140px; }

.img--transactions {
  height: 120px;
  background-size: cover;
  background-position: center bottom;
  border-radius: 10px 10px 0 0; }

.mw-1800 {
  max-width: 1800px; }

[class*='container-fluid'] {
  padding: 5px;
  max-width: 1700px; }
  @media (max-width: 991px) {
    [class*='container-fluid'] {
      max-width: 100%;
      padding: 0 !important; } }
  [class*='container-fluid'][class*='--2'] {
    max-width: 1700px; }
  [class*='container-fluid'][class*='--no-padding'] {
    padding: 0 !important; }

.w-10 {
  width: 15% !important; }

.br-5 {
  border-radius: 25px; }

.bg-black {
  background-color: #625c5c; }

.bg-homepage {
  background-color: #0f0d0d;
  border-radius: 10px; }

.bg-location {
  background-color: #243040;
  border-radius: 10px; }

.bg-blog {
  background-color: #937865;
  border-radius: 10px; }

.text-yellow {
  color: #ffd000; }

.text-italic {
  font-style: italic; }

.text-blue {
  color: #6c92b0; }

.row {
  margin: 0; }

.img-radius {
  border-radius: 15px;
  box-shadow: 16px 11px 27px -16px #625c5c; }

.no-shadow {
  box-shadow: none; }

.sticky--documents {
  position: sticky;
  top: 150px; }

.font-weight-medium {
  font-weight: 500; }

.font-weight-bold {
  font-weight: 600; }

b, strong {
  font-weight: 500; }

.hidden-pics {
  opacity: 0; }

.popup-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer; }

.grecaptcha-badge {
  bottom: 5px !important;
  box-shadow: #f5f5f5 0px 0px 5px !important; }

.img-fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  visibility: hidden; }

.img-fade-in.loaded {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out; }

[class*='template-page'] {
  margin: 20px auto;
  width: 95%;
  position: relative;
  border-radius: 10px;
  border-radius: 1em; }
  @media (max-width: 995px) {
    [class*='template-page'] {
      width: 96%; } }
  [class*='template-page']:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background-color: #FFF; }
  [class*='template-page'][class*='--menu'] {
    position: sticky;
    top: 10px;
    border-radius: 15px; }
  [class*='template-page'][class*='--transparent'] {
    background: url("https://i.ibb.co/nB4VdBX/bg-agency.jpg");
    background-size: cover;
    background-repeat: no-repeat; }
  [class*='template-page'][class*='--no-border'] {
    border-radius: 0;
    background: #FFF;
    border-radius: 1em; }
    [class*='template-page'][class*='--no-border']:before {
      background: #FFF; }
  [class*='template-page'][class*='--connexion'] h1 {
    font-weight: 600;
    font-size: 1rem;
    text-align: center; }
  [class*='template-page'][class*='--connexion'] .newsletter--logo {
    max-width: 30px; }
  [class*='template-page'][class*='--mentions'] h2 {
    font-weight: 600;
    font-size: 1rem;
    color: #dc006b; }
  [class*='template-page'] h3 {
    color: #aba3a3ab;
    text-align: center;
    font-weight: 400; }
  [class*='template-page'][class*='--promotion'] h3 {
    font-size: 1.5rem;
    color: #625c5c; }
  [class*='template-page'][class*='--promotion'] h2 {
    font-size: 2rem;
    color: #aba3a3ab; }
  [class*='template-page'][class*='--promotion'] ul li::before {
    content: "\2022";
    color: #dc006b;
    display: inline-block;
    width: 1em;
    margin-left: -1em; }
  [class*='template-page'][class*='--promotion'] p, [class*='template-page'][class*='--promotion'] ul, [class*='template-page'][class*='--promotion'] h6 {
    color: #625c5c;
    font-weight: 400;
    line-height: 24px;
    font-size: 17px;
    text-align: justify; }
  [class*='template-page'][class*='--team'] h1, [class*='template-page'][class*='--team'] h2 {
    font-size: 50px;
    font-family: 'Dancing Script', cursive !important;
    color: #dc006b;
    margin: 0 0 30px 0;
    text-align: center; }
    @media (max-width: 575px) {
      [class*='template-page'][class*='--team'] h1, [class*='template-page'][class*='--team'] h2 {
        font-size: 35px; } }
  [class*='template-page'][class*='--team'] h3 {
    font-size: 1.5rem;
    color: #625c5c;
    text-align: left; }
    @media (max-width: 575px) {
      [class*='template-page'][class*='--team'] h3 {
        text-align: center;
        margin-top: 10px; } }
  [class*='template-page'][class*='--team'] h4 {
    font-size: 1.2rem;
    color: #625c5c; }
  [class*='template-page'][class*='--team'] h5 {
    color: #dc006b;
    font-size: 14px;
    font-weight: 500; }
  [class*='template-page'][class*='--team'] .name-service {
    font-size: 40px;
    font-family: 'Dancing Script', cursive !important;
    color: #dc006b;
    text-align: center; }
    @media (max-width: 575px) {
      [class*='template-page'][class*='--team'] .name-service {
        font-size: 35px; } }
  [class*='template-page'][class*='--documents'] {
    background-color: #FFF;
    color: #1e3056;
    padding: 10px 20px; }
    @media (max-width: 1855px) {
      [class*='template-page'][class*='--documents'] {
        padding: 10px 0; } }
    [class*='template-page'][class*='--documents'] h1 {
      font-weight: 500;
      color: #dc006b;
      font-size: 1.2rem; }
    [class*='template-page'][class*='--documents'] h2 {
      color: #dc006b;
      font-size: 1rem; }
    [class*='template-page'][class*='--documents'] ul li::before {
      content: "\2022";
      /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: red;
      /* Change the color */
      font-weight: bold;
      /* If you want it to be bold */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 1em;
      /* Also needed for space (tweak if needed) */
      margin-left: -1em;
      /* Also needed for space (tweak if needed) */ }
    [class*='template-page'][class*='--documents'] .img-documents-location {
      max-width: 500px; }
      @media (max-width: 991px) {
        [class*='template-page'][class*='--documents'] .img-documents-location {
          position: absolute;
          top: 0;
          right: 0;
          max-width: 150px; } }
      @media (max-width: 700px) {
        [class*='template-page'][class*='--documents'] .img-documents-location {
          position: static;
          display: flex;
          margin: auto; } }
  [class*='template-page'][class*='--location'] {
    padding: 0 25px 0 25px; }
    @media (max-width: 1335px) {
      [class*='template-page'][class*='--location'] {
        padding: auto 0 !important; } }
  [class*='template-page'][class*='--indexLocations'] h3, [class*='template-page'][class*='--indexLocations'] h2 {
    color: #dc006b;
    font-size: 15px;
    text-align: left; }
  [class*='template-page'][class*='--indexLocations']::after {
    background-repeat-y: no-repeat;
    display: block;
    content: '';
    margin: auto;
    height: 2px;
    width: 80%; }
  [class*='template-page'][class*='--indexLocations'] .img-text {
    height: 16rem;
    object-fit: cover;
    border-radius: 120px 120px 0 0; }
    @media (max-width: 570px) {
      [class*='template-page'][class*='--indexLocations'] .img-text {
        border-radius: 25px 25px 0 0; } }
  [class*='template-page'][class*='--transaction'] h3, [class*='template-page'][class*='--transaction'] h2 {
    color: #dc006b; }
  [class*='template-page'][class*='--transaction']::after {
    background-repeat-y: no-repeat;
    display: block;
    content: '';
    margin: auto;
    height: 2px;
    width: 80%; }
  [class*='template-page'][class*='--blog']::after {
    background-repeat-y: no-repeat;
    background-position: top;
    height: 355px;
    width: 100%;
    display: block;
    content: '';
    border-radius: 0 0 10px 10px; }
  [class*='template-page'] ul li::marker {
    color: #dc006b; }

[class*='row-side-pic'] {
  background-repeat-y: no-repeat;
  background-position: top;
  background-size: cover;
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: block;
  content: '';
  border-radius: 10px;
  box-shadow: 10px 10px 10px #f5f5f5;
  overflow: hidden; }
  @media (max-width: 991px) {
    [class*='row-side-pic'] {
      min-height: 200px;
      clip-path: none !important; } }
  [class*='row-side-pic'][class*='--location'] {
    background-position: center;
    min-height: 200px;
    width: 100%; }
    @media (max-width: 570px) {
      [class*='row-side-pic'][class*='--location'] {
        min-height: 130px;
        clip-path: none !important; }
        [class*='row-side-pic'][class*='--location'] h1 {
          font-size: 15px;
          top: 20px;
          position: absolute; } }
  [class*='row-side-pic'][class*='--infoCity'] {
    min-height: 250px; }
    [class*='row-side-pic'][class*='--infoCity'] h1, [class*='row-side-pic'][class*='--infoCity'] h2 {
      background-color: rgba(98, 92, 92, 0.6); }
    @media (max-width: 570px) {
      [class*='row-side-pic'][class*='--infoCity'] {
        min-height: 130px; } }
  [class*='row-side-pic'][class*='--carte'] {
    height: auto;
    min-height: 340px; }
    @media (max-width: 570px) {
      [class*='row-side-pic'][class*='--carte'] {
        min-height: 170px;
        background-position: bottom;
        clip-path: none !important; }
        [class*='row-side-pic'][class*='--carte'] h1 {
          font-size: 15px;
          top: 20px;
          position: absolute; }
        [class*='row-side-pic'][class*='--carte'] h2 {
          font-size: 15px;
          top: 65px; } }
  [class*='row-side-pic'][class*='--blog'] {
    background-position: center;
    min-height: 200px;
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 122px;
    border-radius: 15px 0 0 15px; }
    @media (max-width: 767px) {
      [class*='row-side-pic'][class*='--blog'] {
        position: relative;
        height: auto; } }
  [class*='row-side-pic'][class*='--transaction'] {
    clip-path: ellipse(126% 186% at -27% 13%); }
  [class*='row-side-pic'][class*='--homepage'] {
    border-radius: 10px;
    background-position: center;
    height: 600px; }
    @media (max-width: 790px) {
      [class*='row-side-pic'][class*='--homepage'] {
        max-width: 100%;
        height: auto; } }
  [class*='row-side-pic'][class*='--promotion'] {
    background-position: center;
    height: 80vh;
    max-height: 750px;
    border-radius: 10px; }
    @media (max-width: 790px) {
      [class*='row-side-pic'][class*='--promotion'] {
        height: 40vh; } }
  [class*='row-side-pic'][class*='--promotion--second'] {
    background-position: center;
    min-height: 600px;
    border-radius: 10px; }

[class*='template-announce'] {
  padding: 1rem 1rem; }
  @media (max-width: 790px) {
    [class*='template-announce'] {
      padding: 10px 0 !important; } }
  [class*='template-announce'][class*='--icon'] .carousel-control-prev-icon,
  [class*='template-announce'][class*='--icon'] .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat 50%;
    background-size: 100% 100%;
    opacity: 1; }
  [class*='template-announce'][class*='--icon'] .carousel-control-next-icon:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f35a';
    font-size: 25px;
    color: #FFF;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  [class*='template-announce'][class*='--icon'] .carousel-control-prev-icon:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f359';
    font-size: 25px;
    color: #FFF;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.mce-notification.mce-has-close {
  display: none; }

.mce-notification.mce-in {
  display: none; }

[class*='alert'] {
  border: none !important;
  border-radius: 0;
  margin-bottom: 0 !important; }
  [class*='alert'] h4 {
    font-size: 21px; }
  [class*='alert'] p {
    font-size: 15px; }
  [class*='alert'] hr {
    border-top-color: #FFF; }

.alert-dismissible .close {
  top: -3px !important; }

.text-sm {
  font-size: 13px; }

.badge.bg-black {
  background-color: #5c012d; }

.image-all-together {
  margin: 20px auto;
  position: relative;
  filter: drop-shadow(0 -2px 11px #f5f5f5); }
  @media (max-width: 1335px) {
    .image-all-together {
      margin: 5px auto; } }
  @media (max-width: 770px) {
    .image-all-together {
      width: 100%; } }

.alert-warning,
.alert-warning > th,
.alert-warning > td {
  background-color: #fff6e9 !important;
  border-radius: 10px;
  text-align: center; }

.alert-success,
.alert-success > th,
.alert-success > td {
  background-color: #00bf86d6 !important;
  animation-duration: .3s;
  animation-name: blind;
  animation-iteration-count: 8;
  transition: none;
  border-radius: 10px;
  text-align: center; }

.alert-danger,
.alert-danger > th,
.alert-danger > td {
  background-color: #dc006b !important;
  animation-duration: .3s;
  animation-name: blind;
  animation-iteration-count: 8;
  transition: none;
  border-radius: 10px;
  text-align: center; }

.alert-info,
.alert-info > th,
.alert-info > td {
  background-color: #f1ece9 !important;
  border-radius: 10px; }

.bg-warning {
  background-color: #fff6e9 !important; }

.modal-dialog {
  max-width: 1000px !important; }

.list-group-item {
  border: 0 !important; }

.klapty {
  width: 100%; }

.klapty iframe {
  display: block;
  width: 100%;
  position: relative;
  pointer-events: none;
  border-radius: 15px; }
  @media (max-width: 991px) {
    .klapty iframe {
      height: 350px; } }

.klapty iframe.clicked {
  pointer-events: auto; }

.block-pointer {
  width: 100%; }

.block-pointer #map {
  display: block;
  width: 100%;
  height: 350px;
  position: relative;
  pointer-events: none;
  border-radius: 15px; }
  @media (max-width: 991px) {
    .block-pointer #map {
      height: 350px; } }

.block-pointer #map.clicked {
  pointer-events: auto; }

.block-pointer iframe {
  display: block;
  width: 100%;
  height: 570px;
  position: relative;
  pointer-events: none;
  border-radius: 15px; }
  @media (max-width: 991px) {
    .block-pointer iframe {
      height: 350px; } }

.block-pointer iframe.clicked {
  pointer-events: auto; }

.block-map {
  width: 100%; }

.block-map #map {
  display: block;
  width: 100%;
  position: relative;
  pointer-events: auto;
  border-radius: 15px; }
  @media (max-width: 991px) {
    .block-map #map {
      height: 350px;
      pointer-events: none; } }

.block-map #map.clicked {
  pointer-events: auto; }

.admin-position {
  display: block;
  background-color: #FFF;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 0 20px 20px 0;
  position: fixed;
  bottom: 75px;
  left: 0;
  z-index: 9999999999999;
  transition: all 1s ease;
  box-shadow: 5px 5px 10px #aba3a3ab; }
  @media (max-width: 991px) {
    .admin-position.inscription {
      display: none; } }
  .admin-position.inscription::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f2bd';
    font-size: 25px;
    color: #5c012d;
    position: absolute;
    left: 12px;
    top: 7px; }
  .admin-position::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f3c1';
    font-size: 25px;
    color: #5c012d;
    position: absolute;
    left: 11px;
    top: 6px; }
  .admin-position:hover {
    cursor: pointer;
    background-color: #dc006b; }
    .admin-position:hover::after {
      color: #FFF; }

#backtotop {
  display: block;
  box-shadow: 5px 5px 10px #aba3a3ab;
  background-color: #FFF;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 20px 0 0 20px;
  position: fixed;
  bottom: 75px;
  right: 0px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000; }
  #backtotop::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f062';
    font-size: 25px;
    color: #5c012d;
    position: absolute;
    left: 14px;
    top: 6px; }
  #backtotop:hover {
    cursor: pointer;
    background-color: #dc006b; }
    #backtotop:hover::after {
      color: #FFF; }
  #backtotop:active {
    background-color: #625c5c; }

#newsletter.show, #backtotop.show {
  opacity: 1;
  visibility: visible; }

.lightwidget__lightbox--active {
  z-index: 999999999999999 !important; }
