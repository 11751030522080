.text-center--pictures {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    padding: 50px 20px;
    margin: auto;
    h3 {
        font-size: 15px;
        padding: 5px;
        font-weight: bold;
    }
    .block-information p{
        color: $dark;
        font-weight: 400;
        line-height: 24px;
        font-size: 17px;
        text-align: justify;
    }
}

.promotion--block {
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 20px 20px 20px $grey--shadow;
    width: 461px;
    height: 244px;
    background: linear-gradient(to bottom right, transparent 0%, transparent 50%, $white 50%, $white 100%);
    @media (max-width: 790px) {
        width: 205px;
        height: 218px;
        position: fixed;
        box-shadow: 1px 31px 51px $grey--shadow;
        z-index: 9999999;
        font-size: 12px;
        background: white;
    }
    .promotion--block-text {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: $rose-dark;
        font-size: 16px;;
    }
    .logo-montfleury {
        position: absolute;
        bottom: 95px;
        right: 0;
        @media (max-width: 790px) {
            bottom: 100px;
        }
    }
}