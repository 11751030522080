
@keyframes appearanceLeft {
  0%   {
    transform: translateX(-50px) translateZ(0);
    opacity:0;
  }
  100% {
    transform: translateX(0) translateZ(0);
    opacity:1;
 }
}

@keyframes appearanceRight {
  0%   {
    transform: translateX(50px) translateZ(0);
    opacity:0;
  }
  100% {
    transform: translateX(0) translateZ(0);
    opacity:1;
 }
}

@keyframes appearanceDown {
  0%   {
    transform: translateY(100px) translateZ(0);
    opacity:0;
  }
  100% {
    transform: translateY(0) translateZ(0);
    opacity:1;
 }
}


@keyframes appearanceFade {
  0%   {
    opacity:0;
  }
  100% {
    opacity:1;
 }
}

@keyframes fadeAnimation {
  0% {
      opacity: 0;
  }
  25% {
      opacity: 0.25;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
}

@keyframes leafDown {
  0%   {
    transform: translateY(-1000px) translateZ(0) rotate(-360deg);
    opacity:1;
  }
  100% {
    transform: translateY(0) translateZ(0);
    opacity:1;
 }
}

@-webkit-keyframes animate-logo {
  from {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  to {
    transform: rotate(20deg);
  }
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
    
}

@-webkit-keyframes zooming {
  50% { transform: scale(0.7);}
  100% { transform: scale(1);}
}


@keyframes blind {
  0%   { opacity:1; }
  40%   {opacity: 0.5;}
  100% { opacity:1; }
}