.resume-infos {
    border-radius: 20px 0 20px 0;
    display: block;
    font-weight: 300;
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  h1 {
    font-weight: 400;
    font-size: 20px;
    text-align: center;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: $rose;
  }

  [class*="--description"] {
      text-align: left;
    .text-lg {
        color : $green-2;
    }
  }
}
span[class*="test"] {
  background: #ffff00;
}
.energie-global {
  margin-top: 0;
  text-align: center;
  @media (max-width: 763px) {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
}
span[class*="energie"] {
    padding: 0 .35rem;
    color: $white;
    font-weight: 600;
    font-size: 11px;
    &.active {
      font-size: 20px;
      padding: 0 10px;
      border: 2px solid $white;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.05);
      box-shadow: 0 2px 4px rgba(0,0,0,.05);
      border-radius: 6px;
    }
    &[class*="--A"] {
      background: #379932;
  }
    &[class*="--B"] {
      background: #3acc31;
  }
    &[class*="--C"] {
      background: #cdfd33;
      color: $black;
  }
    &[class*="--D"] {
      background: #fbea49;
      color: $black;
  }
    &[class*="--E"] {
      background: #fccc2f;
      color: $black;
  }
    &[class*="--F"] {
      background: #fb9c34;
  }
    &[class*="--G"] {
      background: #fa1c1f;
  }
}

span[class*="ges"] {
  padding: 0 .35rem;
  color: $black;
  font-weight: 600;
  font-size: 11px;
  &.active {
    font-size: 20px;
    padding: 0 10px;
    border: 2px solid $white;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.05);
    box-shadow: 0 2px 4px rgba(0,0,0,.05);
    border-radius: 6px;
  }
  &[class*="--A"] {
    background: #f6edfe;
}
  &[class*="--B"] {
    background: #e4c7fb;
}
  &[class*="--C"] {
    background: #d2adf1;
}
  &[class*="--D"] {
    background: #c99aef;
}
  &[class*="--E"] {
    background: #b77ae9;
    color: $white;
}
  &[class*="--F"] {
    background: #a659e9;
    color: $white;
}
  &[class*="--G"] {
    background: #8835d9;
    color: $white;
  }
}

.city-picture {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
}
[class*="text-city"] {
  padding: 0 50px;
  text-align: justify;
  @media (max-width: 991px) {
    padding: 0;
  }
  &[class*="--transaction"] {
    padding: 30px 30px 30px 0;
    @media (max-width: 991px) {
      padding: 30px 30px 0 30px;
    }
  }
}

