#map {
  z-index: 0;
  display: block;
  border-radius: 20px;
  position: relative;
}
.btn-map {
  position: absolute;
  bottom: 5px;
  left: 16px;
}
.btn-bigmap {
  position: absolute;
  bottom: 20px;
  left: 30px;
}
.template-announce #map {
  height: 350px;
}

.map-global {
  height: 80vh !important;
}
.map-info {
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }
  @media (max-width: 463px) {
    font-size: 12px;
  }
}
.map--location {
    height: 29.5rem !important;
}

.block-pointer #map {
  display: block;
  width: 100%;
  position: relative;
  @media (max-width: 991px) {
    pointer-events: none;
  }
  border-radius: 15px;
}

.block-pointer #map.clicked {
  pointer-events: auto;
}

.img-map {
  transform: rotate(-7deg);
}

.marker.is-active {
  z-index: 300;
  background-color: $black !important;
  color: $white;
  &::after {
    border-top-color: $black !important;
  }
}
.marker.is-expanded {
  z-index: 301;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  height: 260px!important;
  width: 200px!important;
  img {
    display: block !important;
  }
  .btn-danger {
    display: block !important;
  }
  #close-button {
    display: block !important;
    color: $white !important;
    background-color: $rose;
    border: 0;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer !important;
  }
  .ref {
    display: block !important;
  }
  .date {
    display: block !important;
  }
}

.marker.is-opened {
  background-color: $rose !important;
  color: $white !important;
  font-weight: 600 !important;
  h2 {
    color: $white !important;
  }
}
.marker.leaflet-popup {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  text-align: center;
  width: 100px;
  // box-shadow: 0 0 10px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 3px $grey;
  border: 1px solid $grey;
  background-color: $white;
  opacity: 0.9 !important;
  img {
    display: none;
  }
  .btn-danger {
    display: none;
  }
  #close-button {
    display: none;
  }
  .ref {
    display: none;
  } 
  .date {
    display: none;
  } 
  a {
    cursor: pointer;
  }
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $black;
  }
}

.marker .leaflet-popup-content-wrapper {
  background: transparent;
  color: inherit;
  box-shadow: none;
  text-align: inherit;
  cursor: pointer;
}

.marker .leaflet-popup-tip-container {
  display: none;
}

.marker .leaflet-popup-content {
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

.leaflet-container a {
  -webkit-tap-highlight-color: $green;
  text-decoration: none;
}
