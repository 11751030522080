.filter {
  .form-control {
    border:none;
    border-bottom: 1px solid $grey;
    padding: 5px 10px;
    outline: none;
    border-radius: 0;
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  
  [placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
  }
  input:in-range {
    box-shadow: none !important;
  }
  .range {
    -webkit-appearance: none;
    border-radius:10px;
    border: 1px solid $grey;
    width: 100%;
    height: 25px;
    background: $grey;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
    &:focus {
      background: $grey;
    }
  
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      background: $black;
      border-radius: 50%;
      cursor: pointer;
    }
    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      background: $black;
      cursor: pointer;
    }
  }
  output {
      margin-bottom: 20px;
      color: $black;
      font-weight: bold;
  }
  
}

.contact-us {
  position: relative !important;
  a {
    color: $grey--2;
    transition: color 0.3s ease;
    &:hover {
      color: $rose;
      text-decoration: none;
      transition: color 1s ease;
    }
  }
  .form-control {
    border: 0;
    background-color: $grey;
  }
  .mail {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f1fa';
    font-size: 100px;
    color: rgba($rose, 0.1);
    transform: rotate(10deg);
    position: absolute;
    right: 25px;
    bottom: 0;
    z-index: 999;
    @media (max-width: 1769px) {
      right: 0;
    }
    @media (max-width: 770px) {
      display: none;
    }
  }
}
.form-filtrage {
  padding: 30px 30px 0;
  color : $dark;
  #submitSearch {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 575px) {
    padding: 0;
    div {
      padding: 0;
    }
    #submitSearch {
      margin-top: 10px;
    }
  }
}
.sortBy {
  position: absolute;
  right: 20px;
  a {
    transition: 0.8s color ease;
    color: $dark;
    text-decoration: none;
    .fas {
      transition: 0.5s all ease;
      transform: rotate(0);
    }
    span {
      font-weight: 600;
    }
    &:hover {
      transition: 0.8s color ease;
      color: $dark;
      span {
        transition: 1s all ease;
      }
      .fas {
        transition: 1.5s all ease;
        color: $dark !important;
        transform: rotate(-360deg);
      }
    }
  }
    @media (max-width: 1205px) {
      position: static;
      width: 100%;
      padding-top: 5px; 
    }
}

.hidden {
  @media (max-width: 575px) {
    display: none;
  }
}

.form-control{
  color: $dark;
  font-weight: bold;
  vertical-align: baseline !important;
  border : 0;
  border-bottom: 1px solid $grey;
  border-radius: 0;
  background:  rgba(255, 255, 255, 0.5);
  &::placeholder {
    font-weight: normal;
    color: $grey-light-2;
  }
  &:focus {
    box-shadow: none !important;
    border-bottom: 1px solid $rose;
  }
}

textarea {
  border: 1px solid $grey !important;
}
