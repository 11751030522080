.footer {
    background-color: $white;
    position: relative;
    height: 100%;
    align-items: center;
    background-image: url('https://i.ibb.co/2kS2Hhd/fondheader.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    animation: appearanceFade 0.1s ease-in 0s forwards;
    font-family: 'Outfit', sans-serif !important;
    // max-width: 1350px;
    // margin: 20px auto 0;
    border-radius: 10px 10px 0 0;
    padding: 30px 120px !important;
    @media (max-width: 991px) {
        padding: 30px !important;
    }
    a {
        color: $dark;
        transition: all 1s ease;
        font-weight: 400;
        &:hover {
            color: $rose;
            text-decoration: none; 
            transition: all 1s ease;
        }
    }

    .container {
        padding: 50px 0 !important;
    }
    .c-logo--footer {
        max-width: 70px;
        
    }
    .load {
        max-width: 200px;
        margin: auto;
        text-align: center;
        display: block;
        animation: appearanceFade 1s ease-in 0s forwards;
    }
    ul {
        list-style:none;
        padding: 0;
        li {
            padding: 4px;
        }
      }
}