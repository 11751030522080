.card {
  border: 0 !important;
}
.card-text {
  font-weight: 400;
  @media (max-width: 575px) {
    padding-bottom: 0 !important;
  }
}

.card-text strong {
  color: $rose-dark;

}

.cardIndex {
  a {
    color: $black;
    text-decoration: none;
    overflow: hidden;
    transition: 1s color ease-in-out;
    &:hover {
      transition: 1s color ease-in-out;
    }
  }
  [class*='card'] {
    border: 0 !important;
    // border-radius: 25px;
    transition: 1s box-shadow ease;
    @media (max-width: 575px) {
      flex-direction: row;
    }
    .surface-piece--location {
      font-size: 25px;
      bottom: 6px;
      right: 25px; 
      @media (max-width: 575px) {
        color: white !important;
        bottom: -15px;
        font-size: 20px;
      }     
    } 
    .card-title {
      font-size: 15px;
      color: $grey-origin;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }  
    .card-description {
      color: $grey-origin;
    } 
    &[class*='--noshadow'] {
      box-shadow: none;
      border-radius: 0;
      &:hover {
        box-shadow: none;
      }
    }
    &:hover {
      transition: 1s box-shadow ease-in-out;
      box-shadow: 8px 6px 41px $grey-light-2;
      .btn--transaction {
        background-color: $rose !important;
        transition: 0.5s all ease-in-out !important;
      }
      .surface-piece--location {
        color: $grey-light-2 !important;
        transition: 0.5s color ease;
      }  
      .card-body {
        background-color: $grey-light;
        transition: 1s all ease-in-out;
      }
      .card-text {
        box-shadow: none;
      }
      .card-title {
        box-shadow: none;
      }
      .claimedRight {
        transition: 0.5s all ease;
        background-color: $white;
      }
      .card-img {
        // border-radius: 10px 10px 0 0;
        // width: 95%;
        // height: 20rem;
        margin: auto;
        overflow: hidden;
        transition: 0.5s all ease-in-out;
        &::before {
          opacity: 0.5;
          transition: 2s all ease-in-out;
        }
        &::after {
          transition: 1s all ease-in-out;
          opacity: 0;
          animation: appearanceDown 0.5s ease-in-out 0.1s forwards;
        }
        // @media (max-width: 575px) {
        //   width: 60%;
        // }
        // @media (max-width: 475px) {
        //   width: 50%;
        // }
      }
      .my360-logo {
        background-color: rgba($rose, 0.8) !important;
      }
      .surface--image {
        background-color: rgba($rose-dark, 0.8);
        transition: 1s ease;
        color: $white;
        // background-color: $black;
      }
    }
    .surface--image {
      opacity: 0;
      animation: appearanceFade 0.6s ease-in 0.8s forwards;
      position: absolute;
      background-color: rgba($white, 0.8);
      color: $black;
      font-weight: bold;
      top: 5px;
      right: 3px;
      padding: 3px 5px 3px 9px;
      border-radius: 20px 20px 0 20px;
      @media (max-width: 575px) {
        right : 0;
        top: 25px;
        border-radius: 20px 0 0 20px;
        background-color: rgba(#000, 0.5);
        color: white;
      }
    }
    .claimedRight {
      // opacity: 0.8;
      position: absolute;
      background-color: rgba($white, 0.8);
      color: $dark;
      // font-weight: 400;
      top: 20.7rem;
      left: 0px;
      font-size: 18px;
      padding: 5px 12px;
      border-radius: 0 20px 0 0;
      transition: all 0.3s ease-in-out;
      @media (max-width: 1500px) {
        top: 17.8rem;
      }
      @media (max-width: 1300px) {
        top: 17.2rem;
        font-size: 14px;
        padding: 4px 10px;
        border-radius: 0 25px 0 0;
      }
      @media (max-width: 575px) {
        top: 17rem;
        bottom: auto;
        font-size: 16px;
        padding: 4px 40px;
      }
    }
    .claimedTop {
      // opacity: 0.8;
      position: absolute;
      background-color: rgba($rose, 0.8);
      color: $white;
      top: 0;
      left: 0px;
      font-size: 12px;
      padding: 5px 10px 5px 5px;
      border-radius: 15px 20px 20px 0;
      transition: all 0.3s ease-in-out;
    }
    .card-pub {
      height: 30rem;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 10px 10px 0 0;
      box-shadow: none;
      background-position: center;
      // width: 400px;
      @media (max-width: 1500px) {
        height: 25rem;
      }
      @media (max-width: 1300px) {
        height: 26rem;
      }
    }
    .card-img-tp {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 23rem;
      border-radius: 25px 25px 0 0 !important;
      background-color: $grey;
      @media (max-width: 1500px) {
        height: 20rem;
      }
      @media (max-width: 1300px) {
        height: 19rem;
      }
      @media (max-width: 575px) {
        // height: 18rem;
        border-radius: 10px;
      }
    }
    .card-img {
      background-image: url('https://agence-immobiliere-coulon.fr/images/divers/no-image.png');
      background-size: cover;
      background-position: center;
      // object-fit: cover;
      // transition: all 0.5s ease;
      height: 100%;
      position: relative;
      margin: auto;
      object-fit: cover;
      display: block;
      // animation: appearanceFade 1s ease-in-out 1 forwards;
      // opacity: 0;
      box-shadow: -28px 24px 8px -24px $grey-light-3;
      &::before {
        background: linear-gradient(32deg, #000000 24%, #4a3838 40%, #ffffff 82%, white 100%);
        // border-radius: 10px 10px 0 0;
        height: 100%;
        width: 100%;
        display: block;
        content: '';
        opacity: 0;
        transition: 0.5s ease-out;
      }
      &::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: '\f00e';
        color: $white;
        position: absolute;
        font-size: 40px;
        top: 45%;
        left: 45%;
        z-index: 999;
        transform: translate(-50%, -50%);
        transition: 2s ease-out;
        opacity: 0;
      }
    }
    .my360-logo {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      font-size: 11px;
      padding: 6px 10px 5px 20px;
      letter-spacing: 2px;
      border-radius: 20px 0 0 0;
      background-color: rgba($rose-dark, 0.7);
      transition: 1s ease;
      color: $white;
      @media (max-width: 575px) {
        left:0;
        right: auto;
        bottom: 31px;
        font-size: 9px;
        padding: 5px 10px;
        border-radius: 20px 20px 0 0;
      }  
    }
    .card-body {
      text-align: left;
      height: 100px;
      padding: 15px 15px 20px;
      background-color: $grey-light-4;
      border-radius: 0 0 25px 25px;
      transition: 1s background-color ease;
      hyphens: auto;
      @media (max-width: 1340px) {
        height: 100px;
      }
      @media (max-width: 575px) {
        height: 15rem;
        width: 40%;
        border-radius: 0 25px 25px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        word-break: break-word;
        width: 50%;
        .text-sm {
          font-size: 12px !important;
        }
        .card-title {
          position: absolute;
          font-weight: 600 !important;
          top: 53px;
          color: white;
          padding: 5px 6px 5px 10px;
          right: 0;
          background-color: rgba(#000, 0.5);
          text-align: right;
          border-radius: 20px 0 0 20px;
        }
        .card-text {
          position: absolute;
          top: 84px;
          padding: 5px 6px 5px 10px !important;
          color: white !important;
          font-weight: 600;
          right: 0;
          background-color: rgba(#000, 0.5);
          text-align: right;
          text-align: right;
          line-height: 13px;
          border-radius: 20px 0 0 20px;
          .text-danger {
            color: #ff9e9e !important;
          }
        }
        .btn {
          display: none;
        }
      }
      @media (max-width: 575px) {
        height: 18rem;
        width: 0;
        padding: 0;
      }
      a {
        text-decoration: none;
        color: $black !important;
        &:hover {
          text-decoration: none;
          color: $black;
        }
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 5px;
        color: $grey-light-2;
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
        transition: 0.5s color ease;
      }
    }
  }
  
  .card.col-lg-3 {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
  
  .admin .card-body {
    height: 100%;
  }  
}
.cardPromotion {
  h4 {
    font-size: 17px;
    font-weight: bold;
  }
  h6 {
    font-size: 15px;
  }
  .card-text {
    font-size: 15px;
  }
}

.card--transactions {
  .card-body {
    height: 85px !important;
    // .btn {
    //   color: rgba($rose, 0.4) !important;
    // }
  }
}

[class*='surface-piece']{
  position: absolute;
  right: 15px;
  bottom: -10px;
  font-size: 40px;
  color: $rose-white;
  font-weight: 400;
  font-style: italic;
  transition: all 0.5s;
  &[class*='--location'] {
    font-size: 20px;
    bottom: -15px;
  }
  &[class*='--transactions'] {
    font-size: 30px;
    bottom: -15px;
  }
}