#top,
#bottom,
#left,
#right {
  background: $white;
  position: fixed;
  // box-shadow: 0 0 15px 1px $grey;
  z-index: 9999999999;
}
#left,
#right {
  top: 0;
  bottom: 0;
  width: 20px;
}
#left {
  left: 0;
  background: linear-gradient(
    32deg,
    #fafafa  0%,
    #ffffff 53%,
    #eeeff3 100%,
    #d7dadf 100%
  );
}
#right {
  right: 0;
  background: linear-gradient(
    32deg,
    #fafafa  0%,
    #fafafa  53%,
    #fafafa  100%,
    #d1d6dc 100%
  );
}

#top,
#bottom {
  left: 0;
  right: 0;
  height: 10px;
}
#top {
  top: 0;
  background: linear-gradient(
    32deg,
    #eeeff3 0%,
    #fafafa 53%,
    #fafafa  100%,
    #fafafa  100%
  );
  box-shadow: none;
}
#bottom {
  bottom: 0;
  background: linear-gradient(
    32deg,
    #fafafa 0%,
    #dde3ec 53%,
    #fafafa  100%,
    #fafafa  100%
  );
}
