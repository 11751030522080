.blog {
    background-color: $grey;
    border-radius: 20px;
    @media (max-width: 790px) {
        padding: 20px 10px !important;
    }
    p a {
        color: $blue-2;
        transition: all 0.51s ease;
        font-weight: bold;
        &:hover {
            color: $rose;
            transition: all 0.51s ease;
            text-decoration: none;
        }
    }
}
.blog .jumbotron {
    display: flex;
    position: relative;
    background: transparent;
    width: 100%;
    padding: 2rem 20px 2rem 0;
    // margin-bottom: 80px;
    h3 {
        font-size : 20px;
        color: $black;
        font-weight: 500;
    }
    p {
        font-size : 1rem;
    }
    &:nth-child(even) {
        flex-flow: row-reverse nowrap;
        h3 {
            color: $black;
            text-align: left;
        }
        .side-text {
            left: 30px;
            text-align: left;
            background-color: $white;
            color: $black;
            box-shadow: 2px 1px 5px $green-3;
            @media (max-width: 790px) {
                left: 0;
            }
            &::after {
                left: -20px;
                bottom: 0;
                background-image: url('https://agence-immobiliere-coulon.fr/images/divers/fly.png');
                height: 50px;
                width: 50px;
                transform: rotate(-16deg);
                @media (max-width: 790px) {
                    display: none;
                  }
            }
        }
        .side-pic {
            left: -40px;
            transform: rotate(4deg);
            // z-index: 2;
            @media (max-width: 790px) {
                left: 0;
                transform: rotate(0);
            }
        }
    }
    &:nth-child(odd) {
        h3 {
            text-align: right;
        }
        .side-pic {
            @media (max-width: 790px) {
                transform: rotate(0deg);
            }
        }
        .side-text {
            @media (max-width: 790px) {
                right: 0;
                transform: rotate(0deg);
            }
            &::after {
                right: -90px;
                top: 0;
                background-image: url('https://agence-immobiliere-coulon.fr/images/divers/fly2.png');
                height: 150px;
                width: 150px;
                @media (max-width: 990px) {
                    display: none;
                  }
            }
        }
    }
    &:hover {
        color: $black;
        text-decoration: none;
        .btn {
            background-color: $rose-dark !important;
        }
        .side-pic {
            transform: rotate(0);
            transition: 1s ease;
        }
    }
    // border: 5px solid $grey;
    .side-text {
        color: $black;
        width: 80%;
        background-color: $white;
        top: 30px;
        position: relative;
        padding: 20px;
        border: 2px solid $white;
        right: 50px;
        box-shadow: 2px 1px 5px $green-3;
        z-index: 1;
        text-align: right;
        padding: 25px 50px;
        @media (max-width: 790px) {
            width: 100%;
        }
        &::after {
            display: block;
            content: "";
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .side-pic {
        position: relative;
        display: block;
        width: 60%;
        max-height: 250px;
        background-color: $white;
        border-radius: 20px;
        left: 30px;
        transform: rotate(-4deg);
        transition: all 1s ease;
        background-size: cover;
        background-position: center;
        box-shadow: 15px 11px 20px -19px $black;     
        z-index: 0;
        @media (max-width: 790px) {
            position: absolute;
            top: 1px;
            height: 200px;
            max-height: 250px;
            width: 100%;
            left: 0;
          }
    }
    .btn {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
    }
}

.blog-article {
    padding: 50px 70px;
    h1 {
        font-size: 30px;
        text-align: left;
        color: $rose;
    }
    h2 {
        font-size: 25px;
        text-align: left !important;
        color: #000 !important;
    }
    h3 {
        font-size: 20px;
        text-align: left !important;
        color: $rose !important;
    }
    h4 {
        font-size: 20px;
        text-align: left !important;
        color: $rose !important;
    }
    @media (max-width: 1000px) {
        padding: 30px 10px;
    }
    p, li {
        font-weight: 400 !important;
        color: #000;
        font-size: 16px;
    }
    p strong {
        color: $success-2 !important;
    }
    span {
        font-weight: 400;
    }
}