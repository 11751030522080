.faq {
  padding: 20px 0 20px;
  background: $grey-light-4;
  min-height: 100vh;
  border-radius: 20px;
}

.faq .section-title {
  margin-bottom: 54px;
}

.faq .section-title h2 {
  margin-bottom: 22px;
}

.faq .accordion .card {
  border: none;
  margin-bottom: 5px;
}

.faq .accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 10px;
}

.faq .accordion .card .card-header {
  border: none;
  border-radius: 10px;
  padding: 0;
}

.faq .accordion .card .card-header h5 {
  padding: 0;
}

.faq .accordion .card .card-header h5 button {
  color: $black;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 0 30px 0 70px;
  height: 80px;
  display: block;
  width: 100%;
  color: rgba(30, 48, 86, 0.8);
  text-align: left;
  background-color: $white;
  -webkit-box-shadow: 0px -50px 140px 0px rgba(69, 81, 100, 0.1);
  box-shadow: 0px -50px 140px 0px rgba(69, 81, 100, 0.1);
  border-radius: 10px 10px 0 0;
  position: relative;
}

.faq .accordion .card .card-header h5 button:after {
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  color: $rose;
  text-align: center;
  border: 1px solid $rose;
  border-radius: 50%;
  line-height: 100%;
  content: '\f067';
  font-size: 10px;
  line-height: 18px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.faq .accordion .card .card-header h5 button.collapsed {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(97, 125, 255, 0.2);
  transition: background-color 1000ms linear;

  &:hover {
    background-color: $grey-light-4;
    transition: background-color 1000ms linear;
  }
}

.faq .accordion .card .card-header h5 button[aria-expanded="true"]:after {
  content: '\f068';
  color: #fff;
  background-color: $rose;
}

.faq .accordion .card .card-body {
  -webkit-box-shadow: 0px 15px 140px 0px rgba(69, 81, 100, 0.1);
  box-shadow: 0px 15px 140px 0px rgba(69, 81, 100, 0.1);
  border-radius: 0 0 10px 10px;
  padding-top: 0;
  margin-top: -6px;
  padding-left: 72px;
  padding-right: 70px;
  padding-bottom: 23px;
  color: rgba(30, 48, 86, 0.8);
  line-height: 30px;
  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}