[class*="card-img-slider"] {
    width: 100%;
    max-height: 600px;
    height: 65vh;
    object-fit: cover;
    border-radius: 20px;
    transition: all 1s;
    @media (max-width: 790px) {
        height: 25vh;
    }
    &::before {
        background: linear-gradient(32deg, #000000 24%, #4a3838 40%, #ffffff 82%, white 100%);
        border-radius: 20px;
        height: 100%;
        width: 100%;
        display: block;
        content: '';
        opacity: 0;
        transition: 0.5s ease-out;
    }
    &::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: '\f00e';
        color: $white;
        position: absolute;
        font-size: 40px;
        top: 45%;
        left: 48%;
        z-index: 999;
        transform: translate(-50%, -50%);
        transition: 2s ease-out;
        opacity: 0;
    }
    &[class*="--nopic"] {
        background-size: 50%;
    }

    &[class*="--transaction"] {
        height: 600px;
        border-radius: 20px;
    }
}

.count-pictures {
    position: absolute;
    bottom: 0;
    left: -1px;
    border-radius: 0 20px 0 0;
    background: white;
    padding: 8px 15px;
    color: $black;
    font-size: 11px;
}

.see-more {
    position: absolute;
    bottom: 0;
    right: -1px;
    border-radius: 20px 0 0 0;
    background: white;
    padding: 10px 20px;
    color: $rose;
    font-size: 20px;
    i {
        animation: zooming infinite 1s;
    }
}

[class*="img-top"] {
display: block;
width: 100%;
background-position-x: center;
background-image: url('https://agence-immobiliere-coulon.fr/images/divers/no-image.png');
height: 420px;
background-size: cover;
background-repeat: no-repeat;
position: relative;
// border-radius: 20px;
// box-shadow: 0 10px 6px -7px #b7b7b7;
// animation: appearanceFade 1s ease-in 0.3s forwards;
// opacity:0;
    &[class*="--small"] {
        background-position-x: 100px;
        background-size: contain;
    }

    &[class*="--blog"] {
        width: 80%;
        margin: auto;
        background-size: cover;
    }

    .animate-text--img h1 {
        color: $white;
        background-color: rgba($dark, 0.2);
        padding: 10px 20px;
        font-weight: 600;
        font-size: 30px;
        position: absolute;
        top: 60px;
        opacity:0;
        animation: appearanceLeft 1s ease-in-out 0.5s forwards;
        border-radius: 0 10px 10px 0;
        letter-spacing: 5px;
        &.h1SliderHome {
            top: 75px;
        }
        @media (max-width: 790px) {
            font-size: 21px;
            top: 40px;
            &.h1SliderHome {
                top: 20px;
                font-size: 15px;
            }
        }
    }
    .animate-text--img h2, span {
        color: $white;
        background-color: rgba($dark, 0.2);
        padding: 10px 20px;
        position: absolute;
        top: 120px;
        opacity:0;
        animation: appearanceRight 1.5s ease-in-out 0.5s forwards;
        font-size: 25px;
        border-radius: 0 10px 10px 0;
        letter-spacing: 3px;
        &.h2SliderHome {
            border-radius: 10px 0 0 10px;
            bottom: 41px;
            right: 0;
            top: auto;
            font-size: 16px;
        }
        @media (max-width: 790px) {
            top: 100px;
            // display: none;
            padding: 5px 20px;
            font-size: 20px;
            background-color: rgba($dark, 0.5);
            &.h2SliderHome {
                bottom: 8px;
                right: 0;
                top: auto;
                padding: 5px 10px 5px 15px;
                font-size: 11px;
            }
        }
    }

    .animate-text--img span {
        @media (max-width: 790px) {
            top: 80px;
            padding: 5px 20px;
            font-size: 12px;
        }
    }
}
.opinion-system {
    text-align: center;
    color: $grey--2;
    position: relative;
    .carousel-item {
        background-color: white;
    }
    .text {
        // bottom: 10px;
        // position: absolute;
        // right: 25px;
        display: block;
        // width: 30%;
        border-radius: 10px 0 10px 0;
        // background: rgba($black, 0.6);
        z-index: 0;
        // bottom: 0;
        // right: 0;
        // @media (max-width: 790px) {
        //     width: 100%;
        // }
        .o-sys-logo {
            position: absolute;
            width: 60px;
            left: 50%;
            top: -8px;
            transform: translate(-50%, -50%);
            @media (max-width: 770px) {
                width: 40px;
                left: 50%;
                top: 0;
            }
        }
        // &::before {
        //     font-family: 'Font Awesome 5 Free';
        //     font-weight: 900;
        //     content: '\f10d';
        //     font-size: 38px;
        //     color: $green;
        //     position: absolute;
        //     left: 10px;
        //     transform: rotate(6deg);
        //     top: -22px;
        // }
    }
}
.carousel-inner {
    width: 95%;
    margin: auto;
}
.carousel-item {
    transition: all 1s;
    background-color: $grey;
    border-radius: 20px;
    @media (max-width: 770px) {
        font-size: 11px;
    }
    &::after {
        // font-family: 'Font Awesome 5 Free';
        content: 'zoom';
        font-size: 38px;
        right: 0;
        bottom: 0;
        display: block;
        content: '';
        opacity: 1;
        transition: 0.5s ease-out;
    }
    &:hover {
        .card-img-slider {
            border-radius: 20px;
            object-fit: cover;
            overflow: hidden;
            transition: 1s ease;
            box-shadow: 0 0 10px 2px $grey;
            &::before {
              opacity: 0.5;
              transition: 2s ease;
            }
            &::after {
              transition: 1s ease;
              opacity: 0;
              animation: appearanceDown 0.5s ease-in-out 0.1s forwards;
            }
        }
    }
}