@media print {
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }
    html, body {
        background-color: white !important;
    }
    .contact-us { display: none; }
    .footer { display: none; }
    #photos-announce { display: none; } 
    .city-picture { display: none; } 
    #print-pictures { display: block !important; }
    #klapty { display: none; }
    #select-for-us{ display: none; }
    #tarteaucitronRoot{ display: none; }
    // .block-pointer #map { display: block;}
    .text-city { display: none; }
    .btn-danger { display: none; }
    #navbar { display: none !important;}
    #print-filter { display: none !important;}
    #print-map { display: none !important;}
    #print-logo { display: block !important;}
    .map-info {display: none !important;}
    nav {display: none !important;}
    .sortBy{display: none !important;}
    .cardIndex  {margin : 0 0 0 15mm;}
    #newsletter{display: none !important;}
    .block-pointer #map{display: none !important;}
    #contactId{display: none !important;}
    #map-section {
        padding-top: 0 !important;
    }
    .text-city--transaction {display: none !important;}
    .adress {border-bottom: 1px solid $rose; padding: 50px 10px 10px;}
}