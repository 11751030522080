.pagination {
    .page-item.active .page-link {
        background-color: $rose !important;
    }
    .page-link {
        background-color: $rose-dark !important;
        border: 0px !important;
        transition: background-color 1s ease;
        padding: 5px 10px;
        &:hover {
            background-color: $rose-dark !important;
            transition: background-color 1s ease;
        }
    }
    padding: 20px;

    &,
    * {
        user-select: none;
    }

    a {
        display: inline-block;
        padding: 0 10px;
        cursor: pointer;

        &.disabled {
            opacity: .3;
            pointer-events: none;
            cursor: not-allowed;
        }

        &.current {
            background: #f3f3f3;
        }
    }
}

.paginate-center {
    margin-left: 20px !important;
    max-width: 80%;
    @media (max-width: 1205px) {
        max-width: 100%;
        justify-content: center;
        margin: auto;
        text-align: center;
        margin-left: 0 !important;
    }
}