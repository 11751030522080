.navBar {
    width: 100%;
    max-width: 1800px;
    display: flex !important;
    justify-content: center;
    margin: 14px auto 0;
    background-color: $white;
    position: relative;
    height: 120px;
    align-items: center;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    position: sticky;
    top: 0;
    box-shadow: 0 16px 15px -19px $grey--shadow;
    z-index: 99989;
    @media (max-width: 1855px) {
      width: 100%;
      margin: 0 auto;
    }
    
    .container-fluid {
      max-width: 100% !important;
    }

    a {
        font-size: 13px;
        font-weight: 500;
    }

    .navbar-logo {
        position: relative;
        &::after {
            display: block;
            content: "...Une histoire de famille";
            position: absolute;
            left: 220px;
            top: 45px;
            font-family: 'Dancing Script', cursive;
            font-size: 20px;
            font-weight: normal;
            color: $black;
            @media (max-width: 1390px) {
              position: relative;
              left: 6px; top:0;
            }
        }
        img {
          max-width: 200px;
        }
    }
    .nav-item.active {
      .btn {
        background-color: $rose;
        color: $white;
        font-weight: 600;
      }
    }
    .btn {
      background-color: $white;
      color: $black;
      padding: 10px 35px;
      // border-radius: 5px 5px 0 0;
      box-shadow: 0px 14px 20px -10px rgb(243, 240, 240);
      transition: background-color 0.3s ease-in-out;
      margin: auto;
      font-weight: 400;
      font-family: 'Outfit', sans-serif;
      font-size: 15px;
      @media (max-width: 1400px) {
        padding: 10px 20px;
      }
      &:hover {
        background-color: $rose;
        color: $white;
        // border-radius: 5px;
        transition: background-color 0.2s ease-in-out;
      }
    }

    .navbar-collapse {
        text-align: right;
        margin-right: 30px;
    }

    .dropdown {
      // position: absolute;
      // right: 50px;
      .dropdown-menu {
        background-color: rgba($black, 0.6);
        padding: 10px;
        right: 0;
        left: auto;
        border: 0;
        opacity: 0;
        animation: appearanceFade 0.5s ease-in-out 0.1s forwards;
        a {
          margin: 10px auto 0;
        }
      }
    }
}

.navbar-brand {
  margin-right: 0;
}

.navbar {
  padding: 0;
  @media (max-width: 1200px) {
    .navbar-collapse {
      width: 40%;
      margin: auto;
      /* padding: 0; */
      text-align: center;
      top: 80px;
      position: absolute;
      right: 0;
      background-color: #FFF;
      border-radius: 0 0 20px 20px;
      box-shadow: 1px 31px 51px $grey;
      .btn {
        box-shadow: none;
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }
  @media (max-width: 425px) {
    .navbar-collapse {
      width: 100%;
    }
  }
}
.navbar-toggler {
  background-color: $white;
  right: 50px;
  color: $black;
}
