.filter {
  display: block;
  // position: sticky;
  top: 10px;
  right: 0;
  // background-color: rgba($white, 0.5);
  padding: 20px;
  border-radius: 15px;
  z-index: 500;
  animation: fadeAnimation 0.5s;
  .noUi-connect {
    background-color: $grey !important;
  }
  .noUi-target {
    box-shadow: 7px 0px 10px $grey !important;
    border: none;
  }
  .noUi-handle {
    box-shadow: 7px 0px 10px $grey !important;
    border: none;
  }
}
