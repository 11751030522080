// body load /////////
.loader{
	background-color: rgba($white, 0.95);
	cursor: wait;
	height: 100vh;
    width: 100%;
	left: 0;
    top: 0;
	position: fixed;
	z-index: 9999;
    img {
        -webkit-animation: zooming 4.2s infinite ease-in-out;
        animation: zooming 4.2s infinite ease-in-out;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 40%;
    }
}
/////////////////////////
// loading pictures ////
.is-loading::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white;
    opacity: 0.5;
    z-index: 4;
}

  .is-loading .spinner-border {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    z-index: 5;
}
///////////////////////////