[class*='btn'] {
  border: 0;
  transition: all 0.51s ease;
  font-family: 'Outfit', sans-serif !important;
    &[class*='--homepage'] {
      color: white !important;
      font-weight: bold;
      font-size: calc(20px + 1 * (85vw - 400px) / 1200);
      padding: 2rem 8rem;
      transition: 0.5s;
      border-radius: 75px;
      @media (max-width: 1335px) {
        padding: 2rem 3rem;
      }
      @media (max-width: 770px) {
        font-size: calc(15px + 1 * (85vw - 400px) / 1200);
        padding: 1rem 1rem;
        width: 100%;
        border-radius: 15px;
      }
        &:hover {
            border-radius: 0;
            transition: 0.7s all ease-in-out;
        }
    }
}
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 95%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: $rose;
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 95%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid $rose;
  border-top: 5px solid $rose;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

.btn-success {
  background-color: $green !important;
  &:hover {
    background-color: $rose !important;
  }
}

.btn-light {
  &:hover {
      background-color: $rose !important;
      color: $white !important;
      i {
        color: $white !important;
      }
  }
}

.btn-warning {
  background-color: $yellow !important;
  &:hover {
    background-color: $rose !important;
    color: $white !important;
  }
}

.btn-danger {
  color: $white !important;
  background-color: $rose !important;
  &:hover {
      background-color: $rose-dark !important;
    a {
        text-decoration: none !important;
      }
  }
  a {
    color: white !important;
  }
}

.btn-green-2 {
  background-color: $green-2 !important;
  &:hover {
      background-color: $rose !important;
  }
}

.btn-dark {
  background-color: $rose-dark !important;
  &:hover {
      background-color: $rose !important;
  }
}

.btn-green {
  background-color: $green-dark !important;
  &:hover {
      background-color: $rose !important;
  }
}
.bg-danger, .btn-danger {
  background-color: $rose !important;
}

.btn-grey {
  background-color: $blue-2!important;
  &:hover {
    background-color: $rose !important;
  }
}

.btn {
  .fa-paper-plane, .fa-chevron-right, .fa-search {
    transform: rotate(0deg);
    transition: 1s all ease-in-out;
  }
  &:hover {
    .fa-paper-plane, .fa-chevron-right, .fa-search {
      transform: rotate(360deg);
      transition: 1s all ease-in-out;
    }
  }
}
.sticky-btn {
  position: fixed;
  bottom: 50px;
  left: 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  z-index: 9999999;
  display: none;
  transition: 1s all ease;
  &.show {
    display: block;
    text-align: center;
    transition: 1s all ease;
  }
  img {
    width: 40px;
    position: absolute;
    left: 50%;
    top: -65%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    transition: top 0.5s ease-in-out;
    z-index: -1;
    @media (max-width: 991px) {
      top: -80%;
    }
  }
  .btn {
    font-size: 13px;
    background-color: rgba($rose-dark, 0.8);
    color: $white;
    border-radius: 15px 15px 0 0;
    transition: background-color 0.3s;
    box-shadow: 3px 4px 18px 0px $black;
    padding: 10px 30px;
    @media (max-width: 991px) {
      padding: 10px 20px;
      font-size: 12px;
    }
    &:hover {
        background-color: $rose;
        transition: background-color 0.3s;
        img {
        transition: top 0.5s ease-in-out;
        top: -80%;
      }
    }
  }
}