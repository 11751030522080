.team {
  font-weight: 300;
  max-width: 1300px;
  margin: auto;
  word-break: break-word;
  @media (max-width: 992px) {
    padding: 35px 10px 0;
  }
  .g-map {
    @media (max-width: 992px) {
      height: 300px;
    }
  }
  .all-team {
    padding: 30px 0 50px;
      @media (max-width: 992px) {
          padding: 30px 0 0;
    }
    @media (max-width: 575px) {
      text-align: center;
    }
  }

  .link-team {
    color: $dark !important;
    -webkit-transition: 0.1s ease-in;
    -o-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    font-weight: 400;
    font-size: 14px;
  }
  .cardTeam {
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    // height: 500px;
    cursor: pointer;
    @media (max-width: 575px) {
      padding: 5px 0;
    }
    &:hover {
      .img-team {
        transform: rotate(-2deg);
        transition: all 0.5s ease-in-out;
        filter: drop-shadow(0 0 20px $grey--shadow) hue-rotate(0.1rad);
        @media (max-width: 992px) {
          transform: none;
        }
      }
    }
  }

  .img-team {
    background-image: url('https://agence-immobiliere-coulon.fr/images/divers/no-image.png');
    background-position: center;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(0deg);
    transition: all 1s ease-in-out;
    object-fit: cover;
    box-shadow: inset 0px -14px 40px $rose-white;
    border-radius: 50%;
    padding: 0;
    // clip-path:ellipse(50% 50%);
    // border: 2px solid $rose;
    @media (max-width: 1024px) {
      margin: auto;
      background-position: center;
      border-radius: 0;
      background-size: contain;
      box-shadow: none;
      height: 270px;
    }
  }
  
  .link-team:hover {
    -webkit-transform: translate3d(0px, -5px, 0px);
    transform: translate3d(0px, -5px, 0px);
    color: $rose !important;
    text-decoration: none;
  }
  
  .fa-mobile-alt {
    margin-left: 2px;
    margin-right: 10px;
  }
  hr {
    border-top: 2px dashed $rose-white;
    width: 100%;
  }

  .subh2 {
    color: $dark;
    font-weight: 400;
    line-height: 24px;
    font-size: 17px;
    text-align: justify;
  }
  
}


