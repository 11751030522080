.fancybox__container {
  z-index: 9999999999999999 !important;
  --fancybox-bg: #ffffffeb;
  // max-width: 1350px;
  margin: auto;
}

.fancybox__nav .f-button {
  color: $rose;
  background: $white;
  text-shadow: 0 0 $white;
  border: 2px solid $rose;
  &:hover {
    color: $white !important;
    background: $rose !important;
  }
  @media (max-width: 1100px) {
    color: $white;
    background: $rose;
    width: 35px;
    height: 35px;
  }
}

.fancybox__toolbar .f-button {
  color: $rose;
  background: $white;
  text-shadow: 0 0 $white;
  &:hover {
    color: $white !important;
    background: $rose !important;
  }
}

.is-idle .fancybox__nav {
  animation: none;
}

.fancybox__infobar {
  color: $rose;
  font-weight: 600;
  text-shadow: 0 0 $white;
  background: $white;
}

.fancybox-image {
  border-radius: 15px;
}
.fancybox__footer {
  background: #ffffffd9 !important;
  border-radius: 20px 20px 0 0;
}