.text-xs {
    font-size: 10px;
}
.text-sm {
    font-size: 12px;
}
.text-md {
    font-size: 14px;
}
.text-lg {
    font-size: 20px;
}
.text-xl {
    font-size: 50px;
}