$white:         #FFF;
$black:         #625c5c;
$dark-2:        #1e3056;
$red:           rgb(161, 82, 82);
$rose:          #dc006b;
$rose-white:    #dc006b1f;
$rose-dark:     #5c012d;
$grey:          #f5f5f5;
$grey--2:       #888;
$grey--shadow:  #aba3a3ab;
$grey-origin:   #4c4c4c;
$grey-light:    #ebebeb36;
$grey-light-2:  #d5d8dc;
$grey-light-3:  #d2d2d2;
$grey-light-4:  #f7f7f7;
$green:         #abcb59;
$green-2:       #78c2ad;
$green-3:       #dbe0cdab;
$green-4:       #00bf86d6;
$green-5:       #009353;
$green-dark:    #93af43;
$success-2 :    #267053;
$blue:          #122c48;
$dark:          #625c5c;
$blue-2:        #6c92b0;
$orange:        orange;
$info-simple:   #f1ece9;
$warning-simple:#fff6e9;
$primary:       #78c2ad;
$yellow:        #ffd000;
$greenBorder:   #b1cd62;
$blue-link:     #2197f5;

$bkg-homepage : #6f6a62;
$bkg-location : #243040;
$bkg-blog : #937865;

.text-danger {
    color: $rose !important;
}

.text-success {
    color: #5db994 !important;
}
.text-success-2 {
    color: $success-2;
}

.text-green {
    color: $green-5 !important;
}

.text-dark {
    color: $rose-dark !important;
}

.text-yellow {
    color: $yellow;
}

a.text-grey {
    color: $grey--2;
    transition: color 1s ease;
}

a.text-danger {
    color: $rose;
    transition: color 1s ease;
    &:hover {
        color: $dark !important;
        text-decoration: none;
        transition: color 1s ease;
    }
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
    background-color: $dark !important;
    color: $white !important;
}

.bg-grey {
    background-color: $grey-light;
}